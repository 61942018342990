import { Box, Button } from '@mui/material';
import { themeColors } from "../utils/constants";
import InciteDark from '../../javascript/images/InciteDark.png'
import "../../stylesheets/chat_page.css"
import IosShareIconDark from '../../javascript/images/IosShareIconDark.png'

const AddAppHomeModal = ({showAddHomeModal, setShowAddHomeModal}) => {

  const handleAccept = () => {
    setShowAddHomeModal(false); // Hide modal
  };

  return (
    <div className="modal">
      <div className="modal-content" style={{backgroundColor: "#374860", top: '70%'}}>
        <img src={InciteDark} style={{maxHeight: '100%', maxWidth: '90px'}}/> 
        
        <h2 className="h2-terms-modal" style={{fontSize: '13px'}}>
          Find your way back to Incite
          <br/>
        </h2>
        <p className="p-terms-modal" style={{fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          Tap 
          <img src={IosShareIconDark} style={{marginLeft: '1px', marginRight: '1px'}}/>
          and "Add to Home Screen"
        </p>

        <svg xmlns="http://www.w3.org/2000/svg" width="247" height="2" viewBox="0 0 247 2" fill="none">
          <path d="M1 1L246 0.999979" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>

        <Box sx={{marginTop: '20px',}}>
          <Button variant="contained" className="i_understand_button" sx={{
            width: '245px',
            height: '38px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            marginTop: '0px',
          }}
          onClick={handleAccept}
          >
          <Box className="base-text i_understand_text" sx={{
            display: 'flex',
            fontWeight: '400', alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '0px',
            color: '#F2F2F2',
            fontSize: '14px',
          }}>
            Close
          </Box>
          </Button>
        </Box>
      </div>
    </div>
  )
}

export default AddAppHomeModal
