import { createConsumer } from "@rails/actioncable";
import { websocketUrl } from "../../utils/constants";

// Define a function that establishes a WebSocket connection
export const connectWebSocket = (setQuestionOnDemand, current_session_id, subscriptionRef, setMessageThreads, showLoadingWaitingMessage, setShowLoadingWaitingMessage, useFullWidth, setUseFullWidth) => {

  const consumer = createConsumer(websocketUrl);
  const subscription = consumer.subscriptions.create(
  { channel: "WebsocketMessageChannel", current_session_id: current_session_id },
  {
    connected() {
    console.log("Connected to websocket channel");
    },
    disconnected() {
      console.log("Disconnected from websocket channel");
      // For example, you can call connectWebSocket() again to reconnect.
      // console.log("Attempting to reconnect...")
      // connectWebSocket(); // pontentially implemenet backoff strategy!!! (reconnect dealys)
    },
    rejected() {
      console.error("Connection rejected!!");
    },
    received(data) {
      if (data["on-demand"]) {
        setTimeout(() => {
          setQuestionOnDemand(true)
        }, 500)
        return
      }

      if (data["overwrite-question"]) {
        setMessageThreads(prevMessageThreads => {
          prevMessageThreads[prevMessageThreads.length - 1].message = data["overwrite-question"]
          return prevMessageThreads;
        });
        return
      }

      let newMessage = {
        createdAt: "2023-09-29T22:28:02Z",
        message: data["message"],
        advancedFilteringMessage: "",
        sender: "bot",
        tickerDescription: [],
        answeredTickerIds: [],
        currentPrice: "",
        closePrice: "",
        pricePctChange1d: "",
        pricePctChangeAh: "",
        pricePctChange24hr: "",
        assetType: "",
        ticker: "",
        currentPriceUpdatedAt: "",
        logoUrls: [],
        tickerPrices: null,
        earningsInfo: null,
        revenueInfo: null,
      }
      setMessageThreads(prevMessageThreads => {
        let newMessageThreads;
        if (prevMessageThreads.length > 0 && prevMessageThreads[prevMessageThreads.length - 1].sender === 'bot') {
          // If the last message is from 'bot', update its message by concatenating the new message string
          newMessageThreads = [...prevMessageThreads];
          let tot_message = newMessageThreads[newMessageThreads.length - 1].message + `${newMessage.message}`;
          tot_message = formatChatResponse(tot_message)
          tot_message = fixPunctuation(null, tot_message)
          newMessageThreads[newMessageThreads.length - 1].message = tot_message;
        } else {
          // Otherwise, add a new message
          newMessageThreads = [...prevMessageThreads, newMessage];
        }
        return newMessageThreads;
      });
      
      if (showLoadingWaitingMessage != null) {
        setShowLoadingWaitingMessage(null)
      }
      if (!useFullWidth) {
        setUseFullWidth(true)
      }
    }
  });
  subscriptionRef.current = subscription
};

function fixPunctuation(prText, text) {
  let removeFirstPrior = false;
  if (prText != null && prText !== "") {
    text = prText + text;
    removeFirstPrior = true;
  }
  // text = "5. The current price of ETH is 2,576.04. The algorithm data\n"
  // text += "1. Apple Inc. (AAPL)- Current price: $185.632. Microsoft Corporation (MST)\n"
  // text += "1. Dogecoin is priced at $0.0322. It is recommended to buy"
  let lastNum = null;
  text = text
    .replace("4. Please remember", "Please remember") // Always add new line for pleae remember statement..
    .replace("5. Please remember", "Please remember")
    .replace("6. Please remember", "Please remember")
    .replace(". Please remember", ".\nPlease remember") // Always add new line for pleae remember statement..
    .replace(/([\.\!\?\,\;])([^(\s|\d)])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/(\.)([•-])/g, "$1\n$2")
    .replace(/(\))(\s-)/g, "$1\n-")
    .replace(/(\))(\s•)/g, "$1\n$2")
    .replace(/(\d)(\s-)/g, "$1\n-")
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([a-zA-Z0-9])([-•])(?=\s)/g, "$1\n$2")
    .replace(/(\d+)\.\s/g, (match, group1) => {
      if (group1 === "0") {
        return match;
      } else {
        let currentNum = parseInt(group1[group1.length - 1], 10);
        if (lastNum !== null && currentNum === lastNum + 1) {
          lastNum = currentNum;
          if (match.length > 4) {
            return match.slice(0, -3) + "\n" + match.slice(-3);
          } else {
            return match;
          }
        } else {
          if (group1.length < 3) {
            lastNum = currentNum;
          }
          return match;
        }
      }
    })
    .replace('Yo Y', 'YoY')
    .replace('e Bay', 'eBay')
    .replace(/([a-zA-Z]:\*\*)(?=\d{4})/, '$1 ')
    .replace(/(\.)(\s-)/g, "$1\n-")
    .replace(/(\.)(\s)(\u2022)/g, "$1\n$3")
    .replace(/(\d)(?![BTM])([a-zA-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])\.(\d)\./g, "$1.\n$2.")
    .replace(/([a-zA-Z]),(\d)/g, '$1, $2')
    .replace(/(\.\d{2}\.)(\d\.)/g, "$1\n$2")
    .replace(/([a-zA-Z\)]):\s-/g, "$1:\n-")
    .replace('MTh', 'M\nTh').replace('BTh', 'B\nTh')
    //  .replace(/(?<!\n)\n(?!\n)/g, "\n\n")   <- This doesn't work in old javascript!
    .replace(/(\?)(\d\.)/g, "$1\n$2")
    .replace(/(\)\.)(\d\.)/g, "$1\n$2")
    .replace(/(\))\s*-/g, "$1\n-")
    // .replace(/(?<!\d)(:)(\d)/g, "$1\n$2")
    text = text.replace(/(^|\D)(:)(\d)/g, function(match, p1, p2, p3) {
      return p1 + p2 + "\n" + p3; // Reconstruct the match with a newline inserted
    })
    .replace(/([a-zA-Z])(##)/g, "$1\n$2")
    .replace(/:\n(\d)/g, ': $1')
    .replace("**: 1.", "**:\n1.")
    .replace(/([a-zA-Z])\*\*(Recommendation)/g, "$1\n\n**$2")
    .replace(/\)\*\*\*\*(?=\w)/g, ')**** ')
    .replace(/(\w)\*\*(?=\w)/g, '$1** ')
    .replace(/\)\*\*(\d\.)/, ')**\n$1')
    .replace(/:\*\*(\d)/g, ': **$1')
    .replace(/([a-zA-Z])(\))(\d)/g, '$1$2 $3');
    // .replace(/\n\n\n/g, "\n") // Replaces 3 linespaces with 1!
    // .replace(/\n\n/g, "\n"); // Replace 2 linespaces with 1!
    // Replace any single newline character that is not preceded or followed by another newline with two newline characters.
    // .replace(/\n/g, (match, offset, string) => {
    //   if (string[offset - 1] !== '\n' && string[offset + 1] !== '\n') {
    //     return "\n\n";
    //   }
    //   return match;
    // });

  if (removeFirstPrior) {
    text = text.substring(1);
  }
  return text;
}

function formatChatResponse(text) {
  // original, does not allow for spaces in the URL:
  //const markdownLinkRegex = /\[([^\]]+)]\((https?:\/\/[^\s)]+)\)/g;
  // This allows for sapces, and then sanitizes.
  const markdownLinkRegex = /\[([^\]]+)]\((https?:\/\/[^\s)]+(?:\s+[^\s)]+)*)\)/g;
  return text.replace(markdownLinkRegex, (match, linkText, url) => {
    const sanitizedUrl = url.replace(/\s/g, '');
    const styles = "fontWeight: bold; color: #2971D7"
    const anchorTag = `<a href="${sanitizedUrl}" styles="${styles}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
    return anchorTag;
  });
}
