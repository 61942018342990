import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import countryList from 'react-select-country-list'
import { useState, useMemo, useEffect } from 'react';

const CountrySelector = ({country, setCountry}) => {
  const options = useMemo(() => countryList().getData(), [])
  const options2 = options.map(i => i.label)

  return (
    <Autocomplete
      value={country}
      onChange={(event, newValue) => {
        setCountry(newValue);
      }}
      id="country-select-demo"
      options={options2}
      sx={{
        '&:hover': {cursor: 'pointer'} }}
      renderInput={(params) => (
        <TextField
          sx = {{'&:hover': {cursor: 'pointer'}}}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: null,
            endAdornment: null,
          }}
          label="Country" 
        />
      )}
      freeSolo // Allows user to input free text if no option matches
    />
  );
}

export default CountrySelector