import { Box, TextField, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PasswordReset = (props) => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [errors, setErrors] = useState([])

  const { appProps } = props
  const { setRegisterNavMessage } = appProps
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState(null)

  const [passwordResetParams, setPasswordResetParams] = useState({
    password: "",
    confirm_password: "",
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    if (passwordResetParams.password !== passwordResetParams.confirm_password) {
      setErrorMessage("Passwords do not match!");
      return;
    }
    
    let user = {
      password: passwordResetParams.password,
      confirm_password: passwordResetParams.confirm_password,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: user,
        token: token,
      })
  };
  fetch('/update_password', requestOptions)
    .then(response => {
      return (response.json())
    })
    .then(data => {
      if (data.error_message) {
        setErrors(data.error_message)
      } else {
        setRegisterNavMessage("Password Successfully Updated!")
        navigate('/register')
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      setErrors(error[0])
    })
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setPasswordResetParams(signInParams => ({
      ...signInParams,
      [name]: value,
    }))
  };

  return (
    <Box sx={{width: '100%', height: '100%'}}><Box>
    <Box sx={{height:'100vh', overflowY: 'auto', padding: '0px 12px', backgroundColor: themeColors.backgroundColor}}>
      <Box className="welcome-container" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: '50px'}}>
      <Box className="base-text" sx={{color: themeColors.color, textAlign: 'center', lineHeight: '180%', marginBottom: '20px',}}>
        <Box sx={{margin: '20px auto', width: '450px', color: 'rgb(255,0,0,.6)'}}>
          {errorMessage}
        </Box>
        Enter your new password and confirm it
      </Box>
      <TextField 
        sx={textFieldStyles}
        inputProps={{ minLength: 8, required: true }}
        InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
        type="password" 
        value={passwordResetParams.password}
        onChange={handleChange}
        autoComplete="off"
        name="password"
        label="Password"/>
      <TextField 
        sx={textFieldStyles}
        inputProps={{ minLength: 8, required: true }}
        InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
        type="password" 
        value={passwordResetParams.confirm_password}//{"12341234"
        onChange={handleChange}
        autoComplete="off"
        name="confirm_password"
        label="Confirm Password"/>


      <Box sx={{marginTop: '10px',}}>
        <Button variant="contained" className="i_understand_button" sx={{
          textTransform: 'none',
          borderRadius: '24px',
          background: themeColors.buttonColor,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
          marginTop: '0px',
        }} 
        onClick={handleSubmit}
        >
        <Box className="base-text i_understand_text" sx={{
          display: 'flex',
          fontWeight: '400', alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '0px',
          color: '#F2F2F2',
        }}>
          Update Password
        </Box>
        </Button>
      </Box>
    </Box>
    </Box>
    </Box>
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={"Reset Password"} />
    </Box>
    </Box>
  )
}
export default PasswordReset

const textFieldStyles = {
  width: '240px',
  maxWidth: '100%', 
  marginBottom: '15px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: themeColors.color,
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: themeColors.color,
  },
  '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
    color: themeColors.color,
    '& fieldset': {
      borderColor: themeColors.color,
    },
    '&:hover fieldset': {
      borderColor: themeColors.color,
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.color,
    },
  },
}
