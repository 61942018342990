
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { isDarkMode, themeColors } from "../../utils/constants";

const StyledButton = styled(Button)(({ theme, isClicked, overrideDarkMode }) => ({
  color: '#fff',
  // marginTop: '20px',
  textTransform: 'none',
  // backgroundColor: themeColors.SignUpButtonColor,//'white',
  backgroundColor: (overrideDarkMode || isDarkMode) ? 'white' : 'rgba(31,33,35,1.0)',
  // width: '450px',
  // maxWidth: '80%',
  width: '100%',
  borderRadius: '30px',
  // border: `1px solid ${themeColors.SignUpButtonColor}`,
  border: '',//`2px solid #ECECEC`,//${themeColors.SignUpButtonColor}`,
  padding: '8px 14px 8px 0px',
  // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))',
  '&:hover': { backgroundColor: (overrideDarkMode || isDarkMode) ? 'white' : 'rgba(31,33,35,1.0)', },
  // '&:hover': {
  //   backgroundColor: isClicked == true ? 'white' : themeColors.SignUpButtonColor,//isClicked ? 'rgba(240, 240, 240)' : 'white',
  //   color: 'rgba(150, 150, 150)',
  // },
  // '&:disabled': {
  //   backgroundColor: themeColors.SignUpButtonColor,
  // }
}));

const PrimaryButton = ({ children, onClick, disabled, isClicked, overrideDarkMode}) => {
  // console.log("props are?", ...props)
  return (
    <StyledButton
      variant="contained"
      disableElevation={true}
      onClick={onClick}
      disabled={disabled}
      isClicked={isClicked}
      overrideDarkMode={overrideDarkMode}
      // {...props}
    >
      {children}
    </StyledButton>
  )
}

export default PrimaryButton

// how many total characters fit in a line before a second line then 
