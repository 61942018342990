import { defaultServerErrorMessage, isProduction } from "../utils/constants";

const regenerateQuestionRecommendations = async (userData, appProps, question) => {
  // NOTE. If we ever want to allow users to make requests without account, will need to add back UUID paramater.
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query:
      `mutation {
        regenerateQuestionRecommendations(question: "${question}") {
          messages
          tickers
          isAdvanced
          advancedType
        }
      }` 
    })
  };
  try {
   const response = await fetch('/graphql', requestOptions)
   const data = await response.json()
   return data.data.regenerateQuestionRecommendations
  } catch(e) {
    console.log('api errors:', e)
    return e
  }
}

const getUserMessages = (lastMessageDate) => {
  // userNotifications().then(r => {
  //   console.log("Notifications?", r)
  // })
  const requestOptions2 = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query:
      `{
        messages(lastMessageDate: "${lastMessageDate}") {
          sender
          message
          advancedFilteringMessage
          createdAt
          tickerDescription
          answeredTickerIds
          ticker
          currentPrice
          currentPriceUpdatedAt
          closePrice
          pricePctChange1d
          pricePctChangeAh
          pricePctChange24hr
          assetType
          logoUrls
          label1
          label2
          label3
          dates
          values1 {
            x
            y
          }
          values2 {
            x
            y
          }
          values3 {
            x
            y
          }
        }
      }` 
    })
  };
  let final = fetch('/graphql', requestOptions2)
    .then(response => {
      return (response.json())
    })
    .then(data => {
      if (data != "success") {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
    })

  return final
}

// Not in use yet..
const userNotifications = async () => {
  const requestOptions2 = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query:
      `{
        getUserNotifications {
          id
          text
          createdAt
          updatedAt
        }
      }` 
    })
  };
  let final = fetch('/graphql', requestOptions2)
    .then(response => {
      return (response.json())
    })
    .then(data => {
      if (data != "success") {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
    })

  return final
}

const sendChatMessages = async (userData, appProps, message, isExplainWhy) => {
  // NOTE. If we ever want to allow users to make requests without account, will need to add back UUID paramater.
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    // tickerPrices {
    //   date
    //   value
    // }
    // earningsInfo {
    //   date
    //   value
    // }
    // revenueInfo {
    //   date
    //   value
    // }
    body: JSON.stringify({
      query: `mutation SendMessage($message: String!, $isExplainWhy: Boolean!) {
        userSendMessage(message: $message, isExplainWhy: $isExplainWhy) {
          botMessage {
            sender
            message
            advancedFilteringMessage
            createdAt
            tickerDescription
            answeredTickerIds
            ticker
            currentPrice
            currentPriceUpdatedAt
            closePrice
            pricePctChange1d
            pricePctChangeAh
            pricePctChange24hr
            assetType
            logoUrls
            label1
            label2
            label3
            dates
            values1 {
              x
              y
            }
            values2 {
              x
              y
            }
            values3 {
              x
              y
            }
          }
          questionsPrepop
          questionsPrepopTickers
          isAdvanced
          advancedType
          showSaveHomePopup
          showUserProfession
          userProfession
          showAskShareMessage
        }
      }`,
      variables: {
        message: message,
        isExplainWhy: isExplainWhy,
      },
    })
  };

  try {
    const response = await fetch('/graphql', requestOptions)
    const data = await response.json()
    if (data.errors && data.errors.length > 0) {
      const error = data.errors[0];
      if (error.code === "AUTH") {
        return { error: "Session Expired" }//Your session has expired, please log in." };
      } else if (error.code === "LIMIT-SAME-QUESTION") {
        return { 
          error: error.message,
          show_share: false,
        };
      } else if (error.code === "LIMIT-SHARE") {
        return { 
          error: error.message,
          show_share: true,
        };
      } else if (error.code === "LIMIT") {
        return { 
          error: error.message,
        };
      } else if (error.code === "LIMIT-TEMP-BAN") {
        return { 
          error: error.message,
        };
      } else if (error.code === "LIMIT-PERM-BAN") {
        return { 
          error: error.message,
        };
      } else if (error.code === "LIMIT-PAYWALL") {
        return { 
          error: error.message,
          show_paywall: true,
        };
      } else if (error.code === "LIMIT-SIGN-UP") {
        return {
          //error: '  ', // don' show anythign in response, they will have button to re-ask question
          error: error.message,
          show_sign_up: true,
        };
      } else if (error.code === "SERVER-AFTER-GENERATED") {
        return {
          error: error.message,
        };
      } else {
        if (isProduction) { // in production, just show graceful error message 
          return {error: defaultServerErrorMessage}
        } else {
          return { error: error.message }
        }
      }
    }
    return data.data.userSendMessage
   } catch(e) {
    console.log("Error!", e)
    return {error: defaultServerErrorMessage}
   }
}

const clearChatMessageHistory = (userData, appProps) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query:
      `mutation {
        clearAllMessages {
          status
        }
      }` 
    })
  };
  let final = fetch('/graphql', requestOptions)
    .then(response => {
      return (response.json())
    })
    .then(response => {
      if (response != "success") {
        return response.data.clearAllMessages
      }
    })
    .catch(error => {
      console.log('api errors:', error)
    })
  return final
}

export {
  getUserMessages, 
  sendChatMessages,
  regenerateQuestionRecommendations, 
  clearChatMessageHistory,
}
