
import { useNavigate } from "react-router-dom"
import { Box, Link, Select, MenuItem, Button } from '@mui/material';
import { useState, useRef } from "react";
import IconButton from '@mui/material/IconButton';
import Logo from "./Logo";
import * as React from 'react';
import { useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '../../javascript/images/MenuIcon.png'
import InciteWhitePaper from '../../../public/InciteWhitePaper.pdf'
import '../../stylesheets/index.css'
// import AboutIcon from '../../javascript/images/AboutIcon.png'
import AboutIconBlue from '../../javascript/images/AboutIconBlue.png'
import { isDarkMode, themeColors } from "../utils/constants";
import ProfileIcon from '../../javascript/images/ProfileIcon.png'
import { handleSharing, ShareModal, ShareButton } from '../utils/share'
import '../../stylesheets/navbar.css'
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useClassNamesOverride } from "@mui/base/utils/ClassNameConfigurator";
import MenuDotsIconLightMode from '../../javascript/images/MenuDotsIconLightMode.png'
import MenuDotsIconDarkMode from '../../javascript/images/MenuDotsIconDarkMode.png'
import WatchlistModal from "./WatchlistModal";
import WatchlistLight from '../../javascript/images/WatchlistLight.png'
import WatchlistDark from '../../javascript/images/WatchlistDark.png'
import { getWatchlistData } from "../api/user";
import { isMobileBrowser } from "../utils/user_agent";

const AccountMenu = (props) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    appProps
  } = props

  const { userData, setUserData } = appProps

  const handleLogout = (event) => {
    event.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    } 
    fetch('/logout', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data != "success") {
          if (data.error_message) {
            appProps.setErrors(data.error_message)
          }
          else {
            //appProps.setUserData(data)
            appProps.clearStateData()
          }
        }
      })
      .catch(error => {
        console.log('api errors:', error)
        // setErrors(error[0])
      })
  }
  let isLoggedIn = !!appProps.userData.user_id
  let loggedInClassName = isLoggedIn ? 'logged-in' : 'logged-out'
  return (
    <React.Fragment>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
        }}>
        
        <Tooltip title="Account settings">
          <Box className={loggedInClassName}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 5,
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-label={'Account Settings Menu'} // Maybe should change to reflect login state?
          >
            { isLoggedIn && 
              <Box sx={{position: 'relative'}}>
                { isDarkMode ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="11.875" stroke="url(#paint0_linear_1765_915)" strokeWidth="1.25"/>
                    <defs>
                      <linearGradient id="paint0_linear_1765_915" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#69CB60"/>
                        <stop offset="1" stopColor="#00C2FF" stopOpacity="0.69"/>
                      </linearGradient>
                    </defs>
                  </svg>
                :
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="11.875" fill="#F5F9FE" stroke="url(#paint0_linear_1765_871)" strokeWidth="1.25"/>
                    <defs>
                      <linearGradient id="paint0_linear_1765_871" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#2971D7"/>
                        <stop offset="1" stopColor="#293AD7" stopOpacity="0.69"/>
                      </linearGradient>
                    </defs>
                  </svg>
                }

                <Box sx={{
                  top: '3px', left: '7.5px', position: 'absolute', 
                  fontSize:'16px', 
                  background: isDarkMode ? 'linear-gradient(180deg, #69CB60 0%, rgba(0, 194, 255, 0.69) 100%)' : 'linear-gradient(180deg, #2971D7 0%, rgba(41, 58, 215, 0.69) 100%)',
                  WebkitBackgroundClip: 'text', // Proper capitalization for Webkit
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent', // Proper capitalization for Webkit
                  color: 'transparent', // Ensuring compatibility with non-webkit browsers
                }}>
                  {appProps.userData.user_initial}
                </Box>

              </Box>
            }
            { !isLoggedIn &&
              <img src={isDarkMode? MenuDotsIconDarkMode : MenuDotsIconLightMode} style={{ marginTop: '6px', maxHeight: '100%', width: '18px' }} />
            }
            {/* { !isLoggedIn &&
              <img src={ProfileIcon} style={{maxHeight: '100%', width: '28px'}}/> 
            } */}
          </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            backgroundColor: themeColors.chatBoxColor,
            color: themeColors.color,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              // bgcolor: 'background.paper',
              bgcolor: themeColors.chatBoxColor,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        { isLoggedIn &&
        <Box>
          <MenuItem onClick={() => {
            handleClose()
            navigate('/account')
          }}>
            Account
          </MenuItem>
          <MenuItem onClick={() => {
            handleClose()
            navigate('/faqs')
          }}>
            FAQs
          </MenuItem>

          <MenuItem onClick={() => {
            handleClose()
            window.open("https://inciteai.com")
          }}>
            Website
          </MenuItem>

          {/* <MenuItem onClick={() => {
          handleClose()
          window.open("https://inciteai.com/about")
        }}>
          About Incite
        </MenuItem> */}
        {/* <MenuItem onClick={() => {
          handleClose()
          appProps.requestClearChatMessageHistory()
        }}>
          Clear History
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose()
          navigate('/terms-of-use')
          }}>
          Terms of Use
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          navigate('/privacy-policy')
          }}>
          Privacy Policy
        </MenuItem>
        {/* <MenuItem onClick={() => {
          handleClose()
          window.open(InciteWhitePaper)
        }}>
          Technology
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose()
          navigate('/feedback')
        }}>
          Feedback
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          navigate('/contact')
        }}>
          Contact
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Log out
        </MenuItem>
        </Box>
        }

        { !isLoggedIn &&
        <Box>
        <MenuItem onClick={() => {
          handleClose()
          setUserData({...userData, uuidHasAccount: true})
          navigate('/register')
        }}>
          Log In
        </MenuItem>

        <MenuItem onClick={() => {
          handleClose()
          navigate('/faqs')
        }}>
          FAQs
        </MenuItem>

        <MenuItem onClick={() => {
          handleClose()
          window.open("https://inciteai.com")
        }}>
          Website
        </MenuItem>
        {/* <MenuItem onClick={() => {
          handleClose()
          window.open("https://inciteai.com/about")
        }}>
          About Incite
        </MenuItem> */}
        {/* <MenuItem onClick={() => {
          handleClose()
          appProps.requestClearChatMessageHistory()
        }}>
          Clear History
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose()
          window.open('https://inciteai.com/terms-and-conditions', '_blank');
          }}>
          Terms and Conditions
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          window.open('https://inciteai.com/privacy-policy', '_blank');
          }}>
          Privacy Policy
        </MenuItem>
        {/* <MenuItem onClick={() => {
          handleClose()
          window.open(InciteWhitePaper)
        }}>
          Technology
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleClose()
          navigate('/feedback')
        }}>
          Feedback
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          navigate('/contact')
        }}>
          Contact
        </MenuItem>
        </Box>
        }
      </Menu>
    </React.Fragment>
  );
}

const Navbar = (props) => {
  const { disallowNav, allowAbout, appProps, showSubscriptionStatus, useLightMode, headerDescription, largeIncite, onQuestionSubmit } = props
  const { watchlistData, setWatchlistData, setShowSignUpModal, lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, showWatchlistBlueDot, setShowWatchlistBlueDot, showWatchlistFlicker } = appProps
  // let navigate // must define only when nav is allowed & is defined within routes
  // if (!disallowNav && allowAbout) {
  const navigate = useNavigate()
  const isMobile = isMobileBrowser()
  // }
  const { userData, setUserData, isRefreshing, setIsRefreshing, hasErroredWatchlist, setHasErroredWatchlist, setShowPaywallModal, setPaywallMessage, } = appProps

  const { setShowChatShare } = appProps

  const useIsDarkMode = !useLightMode==true && isDarkMode

  let isLoggedIn = !!appProps.userData.user_id

  let classNavbarText = useIsDarkMode ?
    "navbar-text-styles dark"
    :
    "navbar-text-styles"
  
  const [modalVisible, setModalVisible] = useState(false);

  const [watchlistVisible, setWatchlistVisible] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    // if (!isMobile) {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setWatchlistVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    // }
  // }, [watchlistVisible, isMobile]);
}, [watchlistVisible]);


  useEffect(() => {
    if (isLoggedIn) {
      getWatchlistData(setWatchlistData)
    }
  }, [isLoggedIn])

  return(  
    <Box className="navbar-container" sx={{ backgroundColor: themeColors.navColor }}>
      <Box className="navbar-hamburger-container">
        <Box className="navbar-hamburger">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0px'
          }}>
            {/* { (!disallowNav && allowAbout && !isLoggedIn) && */}
            { false &&
            <Box className={classNavbarText} sx={{marginLeft: '20px', display: 'flex', alignItems: 'center'}}>
              About
              <IconButton
                onClick={() => {
                  window.open("https://inciteai.com/about")
                }}
                size="small"
                sx={{ ml: 0}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="About"   // This is the accessible name for the button
              >
                <img src={AboutIconBlue} alt="" style={{maxHeight: '100%', width: '12px'}}/> 
              </IconButton>
              
            </Box>
            }

            {/* PRE-LAUNCH, ONLY ADMIN CAN SEE!! */}
            { (window.location.pathname == '/chat') &&
            // { (window.location.pathname == '/chat') && appProps && appProps.userData && appProps.userData.is_admin_user &&
              <Box sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} onClick={() => {
                if (isLoggedIn) {
                  setShowWatchlistBlueDot(false)
                  setWatchlistVisible(!watchlistVisible)
                } else {
                  // navigate('/register')
                  setShowSignUpModal(true)
                }
              }}>
                <img className={showWatchlistFlicker ? "fade-animation" : 'unset'} src={isDarkMode? WatchlistDark : WatchlistLight} style={{ marginLeft: '20px', marginTop: '0px', maxHeight: '100%', width: '28px' }} />
                { showWatchlistBlueDot &&
                  <Box className={showWatchlistFlicker ? "fade-animation" : 'unset'} sx={{backgroundColor: 'rgba(78, 120, 241, 1.0)', position: 'absolute', left: '35px', top: '17px', width: '12px', height: '12px', borderRadius: '50%' }}/>
                }
              </Box>
            }

            { (allowAbout && isLoggedIn) &&
              <Box className={classNavbarText} sx={{marginLeft: '20px', display: 'flex', alignItems: 'center'}}>
                <ShareModal modalVisible={modalVisible} setModalVisible={setModalVisible}/>
                {/* <ShareButton setModalVisible={setModalVisible} setShowChatShare={setShowChatShare}/> */}
              </Box>
            }
          </Box>
        </Box>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {watchlistVisible && 
          <WatchlistModal
            setWatchlistVisible={setWatchlistVisible} 
            modalRef={modalRef} 
            watchlistData={watchlistData} 
            setWatchlistData={setWatchlistData} 
            onQuestionSubmit={onQuestionSubmit}
            lastRequestedUpdateWatchlist={lastRequestedUpdateWatchlist}
            setLastRequestedUpdateWatchlist={setLastRequestedUpdateWatchlist}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
            hasErroredWatchlist={hasErroredWatchlist}
            setHasErroredWatchlist={setHasErroredWatchlist}
            userData={userData}
            setPaywallMessage={setPaywallMessage}
            setShowPaywallModal={setShowPaywallModal}
          />
        }
        { headerDescription ?
          <h1 className="base-text main-header-text" style={{color: themeColors.color}}>
            {headerDescription}
          </h1>
        :
          <Logo largeIncite={largeIncite}/>
        }
      </Box> 
      <Box className="navbar-hamburger-container">
        <Box className="navbar-hamburger">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0px'
          }}>
            { headerDescription == "Sign up" 
            ?
              <Box sx={{ width: '80px', fontSize: '11px'}} className="base-text sign-text" >
                <Link 
                  sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                  onClick={() => {
                    setUserData({...userData, uuidHasAccount: true})
                    navigate('/register')
                  }}
                >Sign In
                </Link>
              </Box>
            :
              <>{ !disallowNav &&
                <AccountMenu appProps={props.appProps} />
              }</>
            }
          </Box>
        </Box>
      </Box>
      <AccountButton appProps={props.appProps}/>
    </Box>
  )
} 

const AccountButton = (props) => {
  const {
    appProps
  } = props
  return (
    <div className="navbar-account-container">
      <p className="navbar-text-styles"
        onClick={() => {
          appProps.setAccountModalOpen(true)
        }}>
        Account
      </p>
    </div>
  )
}

export { Navbar }
