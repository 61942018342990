import { Box, Button } from '@mui/material';
import { isDarkMode, themeColors } from "../utils/constants";
import Incite from '../../javascript/images/Incite.png'
import InciteDark from '../../javascript/images/InciteDark.png'
import "../../stylesheets/chat_page.css"
import Cookies from 'universal-cookie';

const TermsModal = ({showTermsModal, setShowTermsModal, setHasAcceptedTerms}) => {

  const handleAccept = () => {
    // Here you can set any logic you'd like to execute when the user accepts the Terms and Conditions
    setShowTermsModal(false); // Hide modal
    setHasAcceptedTerms(true)
    // SEt cookie that they have accepted
    const cookies = new Cookies();
    cookies.set('hasAcceptedTerms', true, { path: '/' });
  };

  return (
    <div className="modal">
      <div className="modal-content" style={{backgroundColor: "#374860"}}>
        <h2 className="h2-terms-modal" style={{fontSize: '20px', marginTop: '10px', marginBottom: '20px'}}>Welcome To</h2>

        <img src={InciteDark} style={{maxHeight: '100%', maxWidth: '100px'}}/> 
        
        <h2 className="h2-terms-modal">Terms and Conditions</h2>
        <p className="p-terms-modal">
          By selecting "Accept", you agree to our<br/><a href='https://inciteai.com/terms-and-conditions' target='_blank' className="a-terms-modal">Terms and Conditions</a> and <a href='https://inciteai.com/privacy-policy' target='_blank' className="a-terms-modal">Privacy Policy</a>.
        </p>

        {/* <button onClick={handleAccept}>Accept</button> */}
        <Box sx={{marginTop: '28px',marginBottom: '28px'}}>
          <Button variant="contained" className="i_understand_button" sx={{
            width: '245px',
            height: '46px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            marginTop: '0px',
          }} 
          // type="submit"
          onClick={handleAccept}
          >
          <Box className="base-text i_understand_text" sx={{
            display: 'flex',
            fontWeight: '400', alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '0px',
            color: '#F2F2F2',
            fontSize: '16px',
          }}>
            Accept
          </Box>
          </Button>
        </Box>
      </div>
    </div>
  )
}

export default TermsModal

