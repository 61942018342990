import { Box } from '@mui/material';

const PrechatVersion = () => {
  return (
    <Box sx={{height: '100%', display: 'flex', flexDirection: "column", justifyContent: 'flex-end'}}>
    <Box sx={{position: 'fixed', left: '0px', right: '0px', bottom: '0px'
    }}>
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Box className="base-text bottom_version_text" sx={{
      }}>
        Incite offers insights, not direct financial advice. (Version 01/10/24)
      </Box>
    </Box>
    </Box>
    </Box>
  )
}

export default PrechatVersion
