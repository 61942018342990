import { Box, Link, TextField, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ForgotPassword = (props) => {
  const { appProps } = props
  const {userData, setUserData} = appProps
  const { setRegisterNavMessage } = appProps
  const navigate = useNavigate()
  const [email, setEmail] = useState(null)
  const [errors, setErrors] = useState([])

  const handleSubmit = (event) => {
    event.preventDefault()
    
    let user = {
      email: email,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({user: user})
  };
  fetch('/email_reset_password', requestOptions)
    .then(response => {
      return (response.json())
    })
    .then(data => {
      if (data.error_message) {
        setErrors(data.error_message)
      } else {
        setRegisterNavMessage(data.message)
        navigate('/register')
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      setErrors(error[0])
    })
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setEmail(value)
  };

  return (
    <Box sx={{width: '100%', height: '100%'}}><Box>
    {/* <Back value={false}/> */}
    <Box sx={{height:'100vh', overflowY: 'auto', padding: '0px 12px', backgroundColor: themeColors.backgroundColor}}>
      <Box className="welcome-container" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: '50px'}}>
      <Box className="base-text" sx={{color: themeColors.color, textAlign: 'center', lineHeight: '180%', marginBottom: '20px',}}>
        Enter your email to receive password reset instructions
      </Box>
      <TextField 
        sx={textFieldStyles}
        inputProps={{ required: true}} 
        InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color,}}} 
        type="email" 
        autoComplete="off"
        value ={email}
        onChange={(e)  => {
          handleChange(e)
        }}
        name="email"
        label="Email Address"
      />

      <Box sx={{marginTop: '10px',}}>
        <Button variant="contained" className="i_understand_button" sx={{
          textTransform: 'none',
          borderRadius: '24px',
          background: themeColors.buttonColor,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
          marginTop: '0px',
        }} 
        onClick={handleSubmit}
        >
        <Box className="base-text i_understand_text" sx={{
          display: 'flex',
          fontWeight: '400', alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '0px',
          color: '#F2F2F2',
        }}>
          Email me
        </Box>
        </Button>
      </Box>


      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{marginTop: '35px', fontSize: '12px'}} className="base-text sign-text">
          <Link 
            sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
            onClick={() => {
              setUserData({...userData, uuidHasAccount: true})
              navigate('/register')
            }}
          >Sign In
          </Link>
        </Box>
      </Box>
    </Box>
    </Box>
    </Box>
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={"Reset Password"} />
    </Box>
    </Box>
  )
}
export default ForgotPassword

const textFieldStyles = {
  width: '320px',
  maxWidth: '100%', 
  marginBottom: '15px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: themeColors.color,
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: themeColors.color,
  },
  '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
    color: themeColors.color,
    '& fieldset': {
      borderColor: themeColors.color,
    },
    '&:hover fieldset': {
      borderColor: themeColors.color,
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.color,
    },
  },
}
