import { Box, Link } from "@mui/material"
import { useEffect, useState, useRef } from "react"
import { ResponseWaiting, ResponseWaitingSecond, OnDemandWaiting } from "./ResponseWaiting";
import '../../../stylesheets/message.css'
import { isDarkMode, watchlistPaywallModalMessage, MAX_WATCHLIST_ITEMS } from "../../utils/constants";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobileBrowser } from "../../utils/user_agent";
import CopyContainer from "../../utils/Copy";
import { handleSharing } from "../../utils/share";
import RefreshDataDark from '../../images/RefreshDataDark.png'
import ShareButtonDark from '../../images/ShareButtonDark.png'
import RefreshData from '../../images/RefreshData.png'
import ShareButton from '../../images/ShareButton.png'
import WatchButtonDark from '../../images/WatchButtonDark.png'
import WatchButton from '../../images/WatchButton.png'
import PaywallSelectCheck from '../../images/PaywallSelectCheck.png'
import WatchlistUpdate from '../../images/WatchlistUpdate.png'
import { updateUserWatchlist } from '../../api/user'
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables, zoomPlugin);

const ChatMessage = (props) => {
  const {
    idx,
    messageThread,
    loadingResponse,
    isLastMessage,
    showLoadingWaitingMessage, 
    setShowLoadingWaitingMessage,
    useFullWidth,
    setModalVisible, setShowChatShare,
    questionOnDemand, setQuestionOnDemand,
    preventQuestionInput, setPreventQuestionInput, onQuestionSubmit, secondaryExperienceType,
    isLoggedIn,
    appProps
  } = props

  const { watchlistData, showWatchlistBlueDot, setShowWatchlistBlueDot, showWatchlistFlicker, setShowWatchlistFlicker } = appProps
  const [copyMessage, setCopyMessage] = useState(false);
  const [showAddWatchlist, setShowAddWatchlist] = useState(false)
  const [showAddingWatchlist, setShowAddingWatchlist] = useState(false)
  const [showAddedatchlist, setShowAddedatchlist] = useState(false)
  const [countOfWatchlistTicker, setCountOfWatchlistTicker] = useState(null)
  const [watchlistCountError, setWatchlistCountError] = useState(false)

  const showLoadingWaitingMessageRef = useRef(showLoadingWaitingMessage);
  showLoadingWaitingMessageRef.current = showLoadingWaitingMessage;
  const questionOnDemandRef = useRef(questionOnDemand);
  questionOnDemandRef.current = questionOnDemand;

  // const chartRef = useRef(null);
  const [hasRendered, setHasRendered] = useState(false)

  const isMobile = isMobileBrowser()

  useEffect(() => {
    if (isLastMessage && props.messageThread.answeredTickerIds && props.messageThread.answeredTickerIds.length > 0) {
      const ids = []
      Object.keys(watchlistData).forEach(k => watchlistData[k].forEach(v => ids.push(v.id)))
      let inList = false
      props.messageThread.answeredTickerIds.forEach(v => {
        if (ids.includes(v)) inList = true
      })
      if (!inList) setShowAddWatchlist(true)
      setCountOfWatchlistTicker(ids.length)
    }
  }, [isLastMessage, props.messageThread.answeredTickerIds, watchlistData])

  
  useEffect(() => {
    if (!loadingResponse && (showLoadingWaitingMessageRef.current !== null)) {
      setShowLoadingWaitingMessage(0)
    }
    if (questionOnDemand) {
      setTimeout(() => {
        if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current == 0)) {
          setShowLoadingWaitingMessage("demand-1")
        }
      }, 2000)
      // setTimeout(() => {
      //   if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current == "demand-1")) {
      //     setShowLoadingWaitingMessage("demand-2")
      //   }
      // }, 8000)
    }
    else {

    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(1)
      }
    }, 10000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(2)
      }
    }, 16000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(3)
      }
    }, 22000)
    setTimeout(() => {
      if (loadingResponse && isLastMessage && (showLoadingWaitingMessageRef.current !== null) && (questionOnDemandRef.current !== true)) {
        setShowLoadingWaitingMessage(4)
      }
    }, 28000)

    }

  }, [loadingResponse && isLastMessage, showLoadingWaitingMessage, questionOnDemand])

  const [chartInstance, setChartInstance] = useState(null);
  const chartContainerRef = useRef(null);

  // if (isLastMessage) {
    useEffect(() => {
      
      //if (isLastMessage && props.messageThread.tickerDescription && props.messageThread.tickerDescription.length < 2 && props.messageThread.tickerPrices) {
        // if (props.messageThread.tickerDescription && props.messageThread.tickerDescription.length < 2 && props.messageThread.values1) {
        if (props.messageThread.tickerDescription && props.messageThread.values2) {
      
      // const pricesDates = props.messageThread.tickerPrices.map((x) => x.date)
      // const prices = props.messageThread.tickerPrices.map((x) => x.value)
      // const earningDates = props.messageThread.earningsInfo.map((x) => x.date)
      // const epss = props.messageThread.earningsInfo.map((x) => x.value)
      
      // console.log("props?", props.messageThread)
      const label1 = props.messageThread.label1
      const label2 = props.messageThread.label2
      const label3 = props.messageThread.label2//3
      const dates = props.messageThread.dates
      const values1 = props.messageThread.values1
      const values2 = props.messageThread.values2
      const values3 = props.messageThread.values2//3

      let showBarChart = false
      const barChartKeywords = [
        "Insider Transactions",
        "Volume",
      ]
      if (barChartKeywords.includes(label1)) {
        showBarChart = true
      }
      if (dates.length < 1 || values2.length < 1) { return }
      // if (dates.length < 1 || values1.length < 1) { return }
        
      setHasRendered(true)

      if (chartContainerRef.current) {

      const element = document.getElementById(`myChart-${idx}`)
      
      if (!element) { return }
      // const ctx = element.getContext('2d');
      
      let barColor = 'rgba(10,10,255, 0.25)';
      let epsColor = 'rgba(10,10,255, 0.75)';//'rgba(252,72,114, 0.7)';
      let revColor = 'rgba(45,143,230, 0.7)';
      let priceColor = 'rgba(95,95,95, 0.45)';
      if (isDarkMode) {
        barColor = 'rgba(100,150,255, 0.45)';
        epsColor = 'rgba(100,150,255, 0.95)'//'rgba(252,72,114, 0.7)';
        revColor = 'rgba(45,143,230, 0.7)';
        priceColor = 'rgba(195,195,195, 0.9)';
      }

      if (chartInstance) {
        chartInstance.destroy();
      }
      const ctx = chartContainerRef.current.getContext('2d');

      let datasets = [
        {
          label: label3,
          data: values3,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderColor: priceColor,
          textColor: priceColor,
          borderWidth: 1,
          yAxisID: 'stockPriceAxis',
          pointRadius: 0, // For the stock price, no need
        }
      ]
      if (values1 && values1.length > 0) {
        datasets = [{
          label: label1,//'Earnings Per Share',
          data: values1,//dates.map((date, index) => ({ x: date, y: values1[index] })),//epss,
          type: showBarChart ? 'bar': null,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderColor: showBarChart ? barColor: epsColor,//epsColor,
          textColor: epsColor,
          color: epsColor,
          labelColor: epsColor,
          borderWidth: showBarChart ? 1 : 2,
          barThickness: 2,
          yAxisID: 'epsAxis',
          backgroundColor: showBarChart ? barColor: epsColor,
          pointRadius: 0,//2, // Size of the point
          pointBackgroundColor: showBarChart ? barColor: epsColor,//epsColor, // Background color of the point
          pointBorderColor: showBarChart ? barColor: epsColor,//epsColor, // Border color of the point
          pointStyle: 'circle' // Shape of the point
        }, datasets[0]]
      }

      const newChartInstance = new Chart(ctx, {
        type: 'line', // Change the type to your desired chart type
        data: {
          labels: dates,//allDates,
          datasets: datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false, 
          scales: {
            
            x: {
              ticks: {
                color: priceColor,
                maxTicksLimit: 20, 
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  const dateStr = dates[value]
                  const date = new Date(dateStr);
                  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  return months[date.getMonth()] + " '" + date.getFullYear().toString().slice(2,4)
                }
              },
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
            },
            epsAxis: {
              type: 'linear',
              display: (values1 && values1.length > 0),
              position: 'left',
              color: epsColor,
              textColor: epsColor,
              beginAtZero: false,

              ticks: {
                color: epsColor,
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  return formatLargeNumbers(Math.round(100*value)/100);
                }
              },
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
              afterDataLimits: axis => {
                if (showBarChart) {
                  axis.max = axis.max * 2;
                }
              },
            },
            stockPriceAxis: {
              type: 'linear',
              display: true,
              position: 'right',
              color: priceColor,
              ticks: {
                color: priceColor,
                font: {
                  size: 11
                },
                callback: function(value, index, values) {
                  return '$' + value.toLocaleString();
                }
              },
              beginAtZero: false,
              grid: {
                display: false, // This will hide the grid lines for the X axis
              },
            },
          },
          plugins: {
              title: {
              display: true,
              text: `${props.messageThread.tickerDescription[0]}`,
              font: {
                size: 14
              },
              position: 'top',
            },
            zoom: {
              pan: {
                enabled: !isMobile,
                mode: 'x',
              },
              zoom: {
                wheel: {
                  enabled: !isMobile,
                },
                pinch: {
                  enabled: !isMobile,
                },
                mode: 'x',
              },
            },
            legend: {
              labels: {
                // CAN MAKE CIRCLE: (Comment out to revert to square)
                usePointStyle: true, // This will use point style (circle) instead of box
                pointStyle: 'circle',
                boxWidth: 30,
                padding: 10, // Optional: if you want some padding
                // You can also set the font color and size here, if needed
                font: {
                  size: 12, // Example size
                  weight: 'bold', // Optional: if you want to set a font weight
                },
                // More styling can be done here as required
              },
              // Other legend options...
            }
          }
        }
      });

      setChartInstance(newChartInstance);

      }
      }


      return () => {
        if (chartInstance) {
          chartInstance.destroy();
        }
      };
      // }

    }, [isLastMessage, hasRendered, props.messageThread.tickerPrices]);

  const {
    isButton
  } = messageThread

  let leftChatClass = isDarkMode ? "left-chat-box dark chat-message" : "left-chat-box chat-message"
  let leftOverlapCoverClass = isDarkMode ? "left-box-overlap-cover dark" : "left-box-overlap-cover"
  let rightChatClass = isDarkMode ? "right-chat-box dark chat-message" : "right-chat-box chat-message"
  let rightOverlapCoverClass = isDarkMode ? "right-box-overlap-cover dark" : "right-box-overlap-cover"

  const handleMouseDown = () => {
    // First check if On mobile or IPad
    if (isMobileBrowser()) {
      setCopyMessage(true);
      setTimeout(() => {
        setCopyMessage(false)
      }, 2000) // Message will be shown for 2 seconds
    }
  }

  let prHeader = false;
  let isHeader = false;
  
  const isAfterHours = !!props.messageThread.closePrice
  const is24hrMarket = (props.messageThread.assetType == "cryptos" || props.messageThread.assetType == "forex")

  let concatString = ""
  if (props.messageThread.message) {
    concatString = props.messageThread.message.concat('', props.messageThread.advancedFilteringMessage)
  }
  return (
    <Box className="chat_message_container" sx={{width: '90%'}}>
      {(props.messageThread.sender == "human") &&
        <Box className="outgoing_container">
        { !isButton &&
          <Box sx={{ margin: 'auto', color: '#FFFFFF' }}>
            <Box className="bubble_container">
              {/* <CopyToClipboard text={props.messageThread.message} onCopy={handleMouseDown}> */}
              <div className={rightChatClass} style={{fontWeight: 500}}>{ props.messageThread.message }
              <Box className={rightOverlapCoverClass}/>
              {copyMessage && <CopyContainer/>}
              </div>
              {/* </CopyToClipboard> */}
            </Box>
          </Box>
        }
        </Box>
      }
      {(props.messageThread.sender == "bot" ) && 
        <Box className="bubble_container" 
          sx={{width: '100%', flexDirection: 'column', justifyContent: 'flex-end'}}>
          {/* <CopyToClipboard text={props.messageThread.message} onCopy={handleMouseDown}> */}
          <Box className={leftChatClass}>
          {/* <CopyToClipboard text={props.messageThread.message} onCopy={handleMouseDown}> */}
          <Box sx={{lineHeight: '172%', fontWeight: isDarkMode ? 300 : 'unset'}}
          >
            {concatString.split('\n').map((str, idx) => {
              // Convert Markdown-like ### Headings to <h3>
              let formattedStr = str.trim(); // Start with the trimmed string
              let extraString = ""
              
              prHeader = isHeader;
              // Check for headers and adjust accordingly
              if (formattedStr.startsWith("####")) {
                let endIdx = formattedStr.indexOf("####", 4);
                if (endIdx !== -1) {
                  extraString = formattedStr.substring(endIdx+4)
                  formattedStr = formattedStr.substring(4, endIdx);
                } else {
                  formattedStr = formattedStr.substring(4);
                }
                formattedStr = `<h4>${formattedStr.trim()}</h4>`;
                formattedStr = formattedStr + extraString
                isHeader = true;
              } else if (formattedStr.startsWith("###")) {
                let endIdx = formattedStr.indexOf("###", 3); // Find the index of the next "###" after the first
                if (endIdx !== -1) {
                  extraString = formattedStr.substring(endIdx+3)
                  formattedStr = formattedStr.substring(3, endIdx); // Extract the text between
                  
                } else {
                  formattedStr = formattedStr.substring(3); // Or just remove the first "###" if no closing "###" found
                }
                formattedStr = `<h3>${formattedStr.trim()}</h3>`; // Wrap in <h3> and trim any extra whitespace
                formattedStr = formattedStr + extraString
                isHeader = true;
              } else {
                isHeader = false;
              }
              
                // Apply formatting for bold and italic text
                formattedStr = formattedStr
                  .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
                  .replace(/\*(.*?)\*/g, '<em>$1</em>'); // Italic
              
              // Render the formatted string with appropriate HTML handling
              return (
                <React.Fragment key={idx}>
                  {/* {idx !== 0 && <br />} */}
                  {idx !== 0 && !isHeader && !prHeader && <br />}
                  {/* We're using dangerouslySetInnerHTML because we're sure the content is safe */}
                  <span dangerouslySetInnerHTML={{ __html: formattedStr }} />
                </React.Fragment>
              );
            })}
          </Box>

          {/* </CopyToClipboard> */}
          {/* Only ever show the same chart data of the same company in one session */}
          {/* Initial query if about 1 company, will just render the price chart */}
          {/* Earnings, Price, Insider Transactions, Revenue,  (everythign on income statemnt & balance sheet) */}
          {/* Up to the last 3 years (12 data points) */}
          { hasRendered &&// isLastMessage &&
            <Box sx={{
              marginTop: isMobile ? '0px' : '8px',
              marginLeft: isMobile ? '0px' :'20px',
              marginRight: isMobile ? '0px' :'20px',
              marginBottom: isMobile ? '0px' :'20px',
              marginTop: isMobile ? '0px' : '6px',
            //marginTop: '8px', marginLeft: '20px', marginRight: '20px', marginBottom: '20px', marginTop: '6px'
            }}>
              <Box id={`chart-container-${idx}`} sx={{
                  minHeight: '300px', width: '100%', marginTop: '10px'}}>
                <canvas ref={chartContainerRef} id={`myChart-${idx}`}></canvas>
              </Box>
            </Box>
          }
            <Box className="answer-description-text" sx={{
              marginTop: '7px',
              color: isDarkMode ? '#848484' : '#888888',//'#B7B7B7',
              display: 'flex', alignItems: 'stretch', justifyContent: 'space-between',
              }}>
              <Box sx={{margin: 'auto auto auto 0px'}}>
                {props.messageThread.tickerDescription && (props.messageThread.tickerDescription.length < 2
                ?
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                { !isDarkMode && props.messageThread.logoUrls.length > 0 &&
                  <div style={{height: '32px', marginRight: '8px'}}>
                    { props.messageThread.logoUrls.map((logoUrl, idx) => {
                      return (
                        <img src={`${logoUrl}`} style={{maxHeight: '100%', mixBlendMode: "multiply"}}/>
                      )
                    })}
                  </div>
                }
                <Box>
                { props.messageThread.tickerDescription.map((td, idx) => {
                  return (
                  (idx == props.messageThread.tickerDescription.length-1) ? 
                    props.messageThread.currentPrice ? td + `  (${props.messageThread.ticker})` : td
                  :
                    td+" | "
                  )
                })}
                { props.messageThread.currentPriceUpdatedAt &&
                  <>
                    { props.messageThread.currentPrice &&// props.messageThread.pricePctChange1d) &&
                      <>
                        { (isAfterHours && !is24hrMarket) ?
                          <>
                          <Box sx={{marginTop: '4px'}}>
                            {/* {`Close Price: `}<b>{`$${props.messageThread.closePrice}`} </b>  */}
                            {`Close Price: `}{`$${props.messageThread.closePrice}`}
                            { props.messageThread.pricePctChange1d != null &&
                              <span style={{color: parseFloat(props.messageThread.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(props.messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                {` (${parseFloat(props.messageThread.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(props.messageThread.pricePctChange1d))/100}%)`}
                                {/*  Could add back "today" but it would need to adjust to show "friday" or whenever that change was calculated */}
                              </span>
                            }
                          </Box>
                          {/* {`After hours: `}<b>{`$${props.messageThread.currentPrice}`} </b> <span style={{color: parseFloat(props.messageThread.pricePctChangeAh) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(props.messageThread.pricePctChangeAh) >= 0 ? 1.0 : 0.75}}> */}
                          { (props.messageThread.pricePctChangeAh != null) &&
                          <>
                            {`After hours: `}{`$${props.messageThread.currentPrice}`} <span style={{color: parseFloat(props.messageThread.pricePctChangeAh) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(props.messageThread.pricePctChangeAh) >= 0 ? 1.0 : 0.75}}>
                              {` (${parseFloat(props.messageThread.pricePctChangeAh) >= 0 ? '+':''}${Math.round(10000*parseFloat(props.messageThread.pricePctChangeAh))/100}%)`}
                            </span>
                          </>
                          }
                          </>
                        :
                          <Box sx={{marginTop: '4px'}}>
                            {`Price: `}<b>{`$${props.messageThread.currentPrice}`} </b> 
                            { (props.messageThread.pricePctChange1d != null) &&
                              <>
                                { is24hrMarket ?
                                  <span style={{color: parseFloat(props.messageThread.pricePctChange24hr) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(props.messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                  {` (${parseFloat(props.messageThread.pricePctChange24hr) >= 0 ? '+':''}${Math.round(10000*parseFloat(props.messageThread.pricePctChange24hr))/100}% last 24hrs)`}
                                  </span>
                                :
                                  <span style={{color: parseFloat(props.messageThread.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(props.messageThread.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                                    {` (${parseFloat(props.messageThread.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(props.messageThread.pricePctChange1d))/100}%)`}
                                  </span>
                                }
                              </>
                            }
                          </Box>
                        }

                        { isLastMessage && 
                          <Box sx={{marginTop: '4px'}}>
                            Last updated: {props.messageThread.currentPriceUpdatedAt}
                          </Box>
                        }
                      </>
                    }
                  </>
                }
                </Box>
                </Box>
                :
                <Box>
                  {`${props.messageThread.tickerDescription.length} investments analyzed`}
                  { props.messageThread.currentPriceUpdatedAt &&
                    <Box sx={{marginTop: '4px'}}>
                      Last updated: {props.messageThread.currentPriceUpdatedAt}
                    </Box>
                  }
                </Box>
              
                )}
              </Box>
              { (isLastMessage && props.messageThread.tickerDescription && props.messageThread.tickerDescription.length > 0) &&
                <Box sx={{
                  // marginRight: '6px', 
                  marginTop: '10px',//'22px'
                  }}>
                  <Box sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                      onClick={() => {
                        handleSharing(setModalVisible, setShowChatShare, true)
                      }}
                    >
                      <img src={isDarkMode ? ShareButtonDark : ShareButton} style={{maxHeight: '16px'}} /> Share
                    </Box>

                    { showAddWatchlist && <>
                      { props.messageThread.answeredTickerIds.length > 0 &&
                        <Box sx={{position: 'relative', display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                          onClick={() => {
                            if (isLoggedIn) {
                              if (appProps.userData && (appProps.userData.has_active_subscription || appProps.userData.is_admin_user)) {  
                                const intIds = props.messageThread.answeredTickerIds.map(v => parseInt(v))
                                if (countOfWatchlistTicker + intIds.length > MAX_WATCHLIST_ITEMS) {
                                  setWatchlistCountError(true)
                                  setTimeout(() => {
                                    setWatchlistCountError(false)
                                  }, 4000)
                                } else {
                                  setWatchlistCountError(false)
                                  updateUserWatchlist(intIds, null, appProps.setWatchlistData)
                                  setShowAddingWatchlist(true)
                                  setShowWatchlistBlueDot(true)
                                  setShowWatchlistFlicker(true)
                                  setTimeout(() => {
                                    setShowAddingWatchlist(false)
                                    setShowAddedatchlist(true)
                                  }, 1300)
                                  setTimeout(() => {
                                    setShowAddWatchlist(false)
                                  }, 2600)
                                  setTimeout(() => {
                                    setShowWatchlistFlicker(false)
                                    setShowAddedatchlist(false)
                                  }, 5000)
                                } 
                              } else {
                                // Show paywall with custom message
                                appProps.setPaywallMessage(watchlistPaywallModalMessage)
                                appProps.setShowPaywallModal(true)
                              }

                            } else {
                              appProps.setShowSignUpModal(true)
                            }
                          }}
                        >
                          { showAddingWatchlist ? 
                            <>
                              <img src={WatchlistUpdate} style={{maxHeight: '16px'}} className={"rotate-animation"} /> Watch
                            </>
                            :
                            <>
                              <img src={showAddedatchlist ? PaywallSelectCheck : (isDarkMode ? WatchButtonDark : WatchButton)} style={{maxHeight: '16px'}} /> Watch
                            </>
                          }

                          { watchlistCountError &&
                            <Box sx={{position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '-86px', width: '272px', top: isMobile ? '-50px':'25px', borderRadius: '8px',  
                            display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
                              <Box sx={{
                                  border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
                                }}
                                  >
                                    <span className={isDarkMode ? "base-text dark" : "base-text"} style={{fontSize: '12px'}}>
                                    {`You can only have ${MAX_WATCHLIST_ITEMS} items at a time in your watchlist. Please remove to add more.`}
                                    </span>
                                </Box>
                              
                            </Box>
                          }

                        </Box>
                      }
                      </>
                    }

                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                      
                      
                      onClick={() => {
                        if (!preventQuestionInput) {
                          setPreventQuestionInput(true)
                          setTimeout(() => {
                            if (!secondaryExperienceType || secondaryExperienceType == "new-user") {
                              onQuestionSubmit("Update")
                            } else if (secondaryExperienceType == "ask-share") {
                              if (questionNumber == 1) {
                                handleSharing(setModalVisible, setShowChatShare, false)
                                setTimeout(() => {
                                  setUserData({...userData, showAskShareMessage: false})
                                }, 1500)
                                setPreventQuestionInput(false)
                                userShareExperienceButton("share")
                              } else {
                                setUserData({...userData, showAskShareMessage: false})
                                setPreventQuestionInput(false)
                                userShareExperienceButton("continue")
                              }
                            }
                          }, 50)
                        }
                      }}


                    >
                      <img src={isDarkMode ? RefreshDataDark : RefreshData} style={{marginTop: '1px', maxHeight: '16px'}} /> Data
                    </Box>
                  </Box>

                    {/* </Link> */}
                </Box>
              }
            </Box>

            <Box className={leftOverlapCoverClass}/>
            {copyMessage && <CopyContainer/>}
          </Box>
        </Box>
      }
      { loadingResponse && isLastMessage &&
      <Box>
        {showLoadingWaitingMessage == 1 && 
          <ResponseWaitingSecond text={"Just a moment"} sx={{width: '130px'}}/>
        }
        {showLoadingWaitingMessage == 2 && 
          <ResponseWaitingSecond text={"Almost there"} sx={{width: '120px'}}/>
        }
        {showLoadingWaitingMessage == 3 && 
          <ResponseWaitingSecond text={"Just a little longer"} sx={{width: '150px'}}/>
        }
        {showLoadingWaitingMessage == 4 && 
          <ResponseWaitingSecond text={"Still working"} sx={{width: '112px'}}/>
        }
        {showLoadingWaitingMessage == 0 && 
         <ResponseWaiting/>
        }
      </Box>
      }

      { loadingResponse && isLastMessage &&
        <>
        {/* {showLoadingWaitingMessage == 'demand-1' && <OnDemandWaiting text={"Updating Data for Analysis"} sx={{width: '197px'}}/>} */}
        {showLoadingWaitingMessage == 'demand-1' && <OnDemandWaiting text={"Updating Real-Time"} sx={{width: '152'}} hideDotDotDot={true}/>}
        {/* {showLoadingWaitingMessage == 'demand-2' && <OnDemandWaiting text={"Analyzing"} sx={{width: '90px'}}/>} */}
        </>
      }
      {/* <OnDemandWaiting text={"Updating Data for Analysis"} sx={{width: '197px'}}/> */}

    </Box>
  )

}

export default ChatMessage

const formatLargeNumbers = (number) => {
  const roundDigits = 0;
  const absNumber = Math.abs(number);
  let formattedNumber;

  if (absNumber >= 1_000_000_000_000) {
    formattedNumber = (absNumber / 1_000_000_000_000).toFixed(roundDigits) + 'T';
  } else if (absNumber >= 1_000_000_000) {
    formattedNumber = (absNumber / 1_000_000_000).toFixed(roundDigits) + 'B';
  } else if (absNumber >= 1_000_000) {
    formattedNumber = (absNumber / 1_000_000).toFixed(roundDigits) + 'M';
  } else if (absNumber >= 1_000) {
    formattedNumber = (absNumber / 1_000).toFixed(roundDigits) + 'K';
  } else {
    formattedNumber = absNumber.toString();
  }

  return (number < 0 ? '-' : '') + formattedNumber;
};
