import { Box } from "@mui/system"
import { isDarkMode } from "./constants"
import { userRatedMessage } from '../api/user'

const ThumbUp = ({ isSelected, setIsSelected }) => {
  return (
    <Box onClick={() => {
      if (isSelected != "up") {
        userRatedMessage("up")
        setIsSelected("up")
      }
    }}>

      { isDarkMode ? 
        <>
        { isSelected == "up" &&
          <Box sx={{'&:hover': {cursor: "pointer"}}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_1840_152)">
            <path d="M12.1668 6.66602H17.5002C17.9422 6.66602 18.3661 6.84161 18.6787 7.15417C18.9912 7.46673 19.1668 7.89066 19.1668 8.33268V10.086C19.1671 10.3038 19.1246 10.5196 19.0418 10.721L16.4627 16.9835C16.3997 17.1362 16.2929 17.2667 16.1556 17.3586C16.0184 17.4504 15.857 17.4994 15.6918 17.4993H1.66683C1.44582 17.4993 1.23385 17.4116 1.07757 17.2553C0.921293 17.099 0.833496 16.887 0.833496 16.666V8.33268C0.833496 8.11167 0.921293 7.89971 1.07757 7.74343C1.23385 7.58715 1.44582 7.49935 1.66683 7.49935H4.5685C4.70191 7.49938 4.83338 7.46738 4.95186 7.40604C5.07033 7.3447 5.17235 7.25581 5.24933 7.14685L9.7935 0.707683C9.85094 0.626277 9.93565 0.568151 10.0323 0.543842C10.1289 0.519533 10.231 0.530653 10.3202 0.575183L11.8318 1.33102C12.2572 1.54365 12.5971 1.89544 12.7949 2.32795C12.9928 2.76045 13.0367 3.24762 12.9193 3.70852L12.1668 6.66602ZM5.8335 8.82268V15.8327H15.1335L17.5002 10.086V8.33268H12.1668C11.913 8.33265 11.6625 8.27463 11.4345 8.16306C11.2065 8.0515 11.007 7.88933 10.8512 7.68892C10.6954 7.48852 10.5875 7.25518 10.5356 7.0067C10.4837 6.75823 10.4893 6.50118 10.5518 6.25518L11.3043 3.29852C11.3279 3.20629 11.3191 3.10878 11.2796 3.02221C11.24 2.93564 11.172 2.86522 11.0868 2.82268L10.536 2.54768L6.611 8.10768C6.40266 8.40268 6.136 8.64435 5.8335 8.82268ZM4.16683 9.16602H2.50016V15.8327H4.16683V9.16602Z" fill="url(#paint0_linear_1840_152)"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_1840_152" x1="10.0002" y1="0.53125" x2="10.0002" y2="17.4993" gradientUnits="userSpaceOnUse">
              <stop stopColor="#69CB60"/>
              <stop offset="1" stopColor="#00C2FF" stopOpacity="0.69"/>
            </linearGradient>
            <clipPath id="clip0_1840_152">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
          </svg>
          </Box>
        }
        </>
      :
        <>
        { isSelected == "up" &&
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1840_144)">
              <path d="M12.1668 6.66602H17.5002C17.9422 6.66602 18.3661 6.84161 18.6787 7.15417C18.9912 7.46673 19.1668 7.89066 19.1668 8.33268V10.086C19.1671 10.3038 19.1246 10.5196 19.0418 10.721L16.4627 16.9835C16.3997 17.1362 16.2929 17.2667 16.1556 17.3586C16.0184 17.4504 15.857 17.4994 15.6918 17.4993H1.66683C1.44582 17.4993 1.23385 17.4116 1.07757 17.2553C0.921293 17.099 0.833496 16.887 0.833496 16.666V8.33268C0.833496 8.11167 0.921293 7.89971 1.07757 7.74343C1.23385 7.58715 1.44582 7.49935 1.66683 7.49935H4.5685C4.70191 7.49938 4.83338 7.46738 4.95186 7.40604C5.07033 7.3447 5.17235 7.25581 5.24933 7.14685L9.7935 0.707683C9.85094 0.626277 9.93565 0.568151 10.0323 0.543842C10.1289 0.519533 10.231 0.530653 10.3202 0.575183L11.8318 1.33102C12.2572 1.54365 12.5971 1.89544 12.7949 2.32795C12.9928 2.76045 13.0367 3.24762 12.9193 3.70852L12.1668 6.66602ZM5.8335 8.82268V15.8327H15.1335L17.5002 10.086V8.33268H12.1668C11.913 8.33265 11.6625 8.27463 11.4345 8.16306C11.2065 8.0515 11.007 7.88933 10.8512 7.68892C10.6954 7.48852 10.5875 7.25518 10.5356 7.0067C10.4837 6.75823 10.4893 6.50118 10.5518 6.25518L11.3043 3.29852C11.3279 3.20629 11.3191 3.10878 11.2796 3.02221C11.24 2.93564 11.172 2.86522 11.0868 2.82268L10.536 2.54768L6.611 8.10768C6.40266 8.40268 6.136 8.64435 5.8335 8.82268ZM4.16683 9.16602H2.50016V15.8327H4.16683V9.16602Z" fill="url(#paint0_linear_1840_144)"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear_1840_144" x1="10.0002" y1="0.53125" x2="10.0002" y2="17.4993" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2971D7"/>
                <stop offset="1" stopColor="#293AD7" stopOpacity="0.69"/>
              </linearGradient>
              <clipPath id="clip0_1840_144">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        }
        </>
      }

      { isSelected != "up" &&
        <Box sx={{'&:hover': {cursor: "pointer"}}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_1840_152)">
          <path d="M12.1668 6.66602H17.5002C17.9422 6.66602 18.3661 6.84161 18.6787 7.15417C18.9912 7.46673 19.1668 7.89066 19.1668 8.33268V10.086C19.1671 10.3038 19.1246 10.5196 19.0418 10.721L16.4627 16.9835C16.3997 17.1362 16.2929 17.2667 16.1556 17.3586C16.0184 17.4504 15.857 17.4994 15.6918 17.4993H1.66683C1.44582 17.4993 1.23385 17.4116 1.07757 17.2553C0.921293 17.099 0.833496 16.887 0.833496 16.666V8.33268C0.833496 8.11167 0.921293 7.89971 1.07757 7.74343C1.23385 7.58715 1.44582 7.49935 1.66683 7.49935H4.5685C4.70191 7.49938 4.83338 7.46738 4.95186 7.40604C5.07033 7.3447 5.17235 7.25581 5.24933 7.14685L9.7935 0.707683C9.85094 0.626277 9.93565 0.568151 10.0323 0.543842C10.1289 0.519533 10.231 0.530653 10.3202 0.575183L11.8318 1.33102C12.2572 1.54365 12.5971 1.89544 12.7949 2.32795C12.9928 2.76045 13.0367 3.24762 12.9193 3.70852L12.1668 6.66602ZM5.8335 8.82268V15.8327H15.1335L17.5002 10.086V8.33268H12.1668C11.913 8.33265 11.6625 8.27463 11.4345 8.16306C11.2065 8.0515 11.007 7.88933 10.8512 7.68892C10.6954 7.48852 10.5875 7.25518 10.5356 7.0067C10.4837 6.75823 10.4893 6.50118 10.5518 6.25518L11.3043 3.29852C11.3279 3.20629 11.3191 3.10878 11.2796 3.02221C11.24 2.93564 11.172 2.86522 11.0868 2.82268L10.536 2.54768L6.611 8.10768C6.40266 8.40268 6.136 8.64435 5.8335 8.82268ZM4.16683 9.16602H2.50016V15.8327H4.16683V9.16602Z" fill="#C2C2C2"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_1840_152" x1="10.0002" y1="0.53125" x2="10.0002" y2="17.4993" gradientUnits="userSpaceOnUse">
            <stop stopColor="#69CB60"/>
            <stop offset="1" stopColor="#00C2FF" stopOpacity="0.69"/>
          </linearGradient>
          <clipPath id="clip0_1840_152">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
        </svg>
        </Box>
      }

    </Box>
  )
}

const ThumbDown = ({ isSelected, setIsSelected }) => {
  return (
    <Box sx={{'&:hover': {cursor: "pointer"}}} onClick={() => {
      if (isSelected != "down") {
        userRatedMessage("down")
        setIsSelected("down")
      }
    }}>
    { isDarkMode ?
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <g clipPath="url(#clip0_1840_155)">
          { isSelected == "down"?
            <path d="M8.5835 13.3333H3.25016C2.80814 13.3333 2.38421 13.1577 2.07165 12.8452C1.75909 12.5326 1.5835 12.1087 1.5835 11.6667V9.91333C1.58327 9.69553 1.62574 9.4798 1.7085 9.27833L4.2885 3.01667C4.35123 2.86396 4.45791 2.73333 4.595 2.64134C4.73209 2.54936 4.89341 2.50016 5.0585 2.5H19.0835C19.3045 2.5 19.5165 2.5878 19.6728 2.74408C19.829 2.90036 19.9168 3.11232 19.9168 3.33333V11.6667C19.9168 11.8877 19.829 12.0996 19.6728 12.2559C19.5165 12.4122 19.3045 12.5 19.0835 12.5H16.1818C16.0484 12.5 15.9169 12.532 15.7985 12.5933C15.68 12.6546 15.578 12.7435 15.501 12.8525L10.9568 19.2908C10.8994 19.3722 10.8147 19.4304 10.718 19.4547C10.6214 19.479 10.5193 19.4679 10.4302 19.4233L8.9185 18.6667C8.49308 18.454 8.15322 18.1022 7.95539 17.6697C7.75756 17.2372 7.71366 16.7501 7.831 16.2892L8.5835 13.3333ZM14.9168 11.1767V4.16667H5.61683L3.25016 9.91333V11.6667H8.5835C8.83733 11.6667 9.0878 11.7247 9.31581 11.8363C9.54381 11.9479 9.74331 12.11 9.8991 12.3104C10.0549 12.5108 10.1628 12.7442 10.2147 12.9926C10.2666 13.2411 10.2611 13.4982 10.1985 13.7442L9.446 16.7008C9.42245 16.7931 9.43118 16.8906 9.47075 16.9771C9.51032 17.0637 9.57834 17.1341 9.6635 17.1767L10.2143 17.4517L14.1393 11.8917C14.3477 11.5967 14.6143 11.355 14.9168 11.1767ZM16.5835 10.8333H18.2502V4.16667H16.5835V10.8333Z" fill="url(#paint0_linear_1840_152)"/>
          :
            <path d="M8.5835 13.3333H3.25016C2.80814 13.3333 2.38421 13.1577 2.07165 12.8452C1.75909 12.5326 1.5835 12.1087 1.5835 11.6667V9.91333C1.58327 9.69553 1.62574 9.4798 1.7085 9.27833L4.2885 3.01667C4.35123 2.86396 4.45791 2.73333 4.595 2.64134C4.73209 2.54936 4.89341 2.50016 5.0585 2.5H19.0835C19.3045 2.5 19.5165 2.5878 19.6728 2.74408C19.829 2.90036 19.9168 3.11232 19.9168 3.33333V11.6667C19.9168 11.8877 19.829 12.0996 19.6728 12.2559C19.5165 12.4122 19.3045 12.5 19.0835 12.5H16.1818C16.0484 12.5 15.9169 12.532 15.7985 12.5933C15.68 12.6546 15.578 12.7435 15.501 12.8525L10.9568 19.2908C10.8994 19.3722 10.8147 19.4304 10.718 19.4547C10.6214 19.479 10.5193 19.4679 10.4302 19.4233L8.9185 18.6667C8.49308 18.454 8.15322 18.1022 7.95539 17.6697C7.75756 17.2372 7.71366 16.7501 7.831 16.2892L8.5835 13.3333ZM14.9168 11.1767V4.16667H5.61683L3.25016 9.91333V11.6667H8.5835C8.83733 11.6667 9.0878 11.7247 9.31581 11.8363C9.54381 11.9479 9.74331 12.11 9.8991 12.3104C10.0549 12.5108 10.1628 12.7442 10.2147 12.9926C10.2666 13.2411 10.2611 13.4982 10.1985 13.7442L9.446 16.7008C9.42245 16.7931 9.43118 16.8906 9.47075 16.9771C9.51032 17.0637 9.57834 17.1341 9.6635 17.1767L10.2143 17.4517L14.1393 11.8917C14.3477 11.5967 14.6143 11.355 14.9168 11.1767ZM16.5835 10.8333H18.2502V4.16667H16.5835V10.8333Z" fill="#C2C2C2"/>
          }
        </g>
        <defs>
          { isSelected  == "down" &&
            <linearGradient id="paint0_linear_1840_152" x1="10.0002" y1="0.53125" x2="10.0002" y2="17.4993" gradientUnits="userSpaceOnUse">
              <stop stopColor="#69CB60"/>
              <stop offset="1" stopColor="#00C2FF" stopOpacity="0.69"/>
            </linearGradient>
          }
          <clipPath id="clip0_1840_155">
            <rect width="20" height="20" fill="white" transform="translate(0.75)"/>
          </clipPath>
        </defs>
      </svg>
    :
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <g clipPath="url(#clip0_1840_155)">
          { isSelected == "down"?
            <path d="M8.5835 13.3333H3.25016C2.80814 13.3333 2.38421 13.1577 2.07165 12.8452C1.75909 12.5326 1.5835 12.1087 1.5835 11.6667V9.91333C1.58327 9.69553 1.62574 9.4798 1.7085 9.27833L4.2885 3.01667C4.35123 2.86396 4.45791 2.73333 4.595 2.64134C4.73209 2.54936 4.89341 2.50016 5.0585 2.5H19.0835C19.3045 2.5 19.5165 2.5878 19.6728 2.74408C19.829 2.90036 19.9168 3.11232 19.9168 3.33333V11.6667C19.9168 11.8877 19.829 12.0996 19.6728 12.2559C19.5165 12.4122 19.3045 12.5 19.0835 12.5H16.1818C16.0484 12.5 15.9169 12.532 15.7985 12.5933C15.68 12.6546 15.578 12.7435 15.501 12.8525L10.9568 19.2908C10.8994 19.3722 10.8147 19.4304 10.718 19.4547C10.6214 19.479 10.5193 19.4679 10.4302 19.4233L8.9185 18.6667C8.49308 18.454 8.15322 18.1022 7.95539 17.6697C7.75756 17.2372 7.71366 16.7501 7.831 16.2892L8.5835 13.3333ZM14.9168 11.1767V4.16667H5.61683L3.25016 9.91333V11.6667H8.5835C8.83733 11.6667 9.0878 11.7247 9.31581 11.8363C9.54381 11.9479 9.74331 12.11 9.8991 12.3104C10.0549 12.5108 10.1628 12.7442 10.2147 12.9926C10.2666 13.2411 10.2611 13.4982 10.1985 13.7442L9.446 16.7008C9.42245 16.7931 9.43118 16.8906 9.47075 16.9771C9.51032 17.0637 9.57834 17.1341 9.6635 17.1767L10.2143 17.4517L14.1393 11.8917C14.3477 11.5967 14.6143 11.355 14.9168 11.1767ZM16.5835 10.8333H18.2502V4.16667H16.5835V10.8333Z" fill="url(#paint0_linear_1840_155)"/>
          :
            <path d="M8.5835 13.3333H3.25016C2.80814 13.3333 2.38421 13.1577 2.07165 12.8452C1.75909 12.5326 1.5835 12.1087 1.5835 11.6667V9.91333C1.58327 9.69553 1.62574 9.4798 1.7085 9.27833L4.2885 3.01667C4.35123 2.86396 4.45791 2.73333 4.595 2.64134C4.73209 2.54936 4.89341 2.50016 5.0585 2.5H19.0835C19.3045 2.5 19.5165 2.5878 19.6728 2.74408C19.829 2.90036 19.9168 3.11232 19.9168 3.33333V11.6667C19.9168 11.8877 19.829 12.0996 19.6728 12.2559C19.5165 12.4122 19.3045 12.5 19.0835 12.5H16.1818C16.0484 12.5 15.9169 12.532 15.7985 12.5933C15.68 12.6546 15.578 12.7435 15.501 12.8525L10.9568 19.2908C10.8994 19.3722 10.8147 19.4304 10.718 19.4547C10.6214 19.479 10.5193 19.4679 10.4302 19.4233L8.9185 18.6667C8.49308 18.454 8.15322 18.1022 7.95539 17.6697C7.75756 17.2372 7.71366 16.7501 7.831 16.2892L8.5835 13.3333ZM14.9168 11.1767V4.16667H5.61683L3.25016 9.91333V11.6667H8.5835C8.83733 11.6667 9.0878 11.7247 9.31581 11.8363C9.54381 11.9479 9.74331 12.11 9.8991 12.3104C10.0549 12.5108 10.1628 12.7442 10.2147 12.9926C10.2666 13.2411 10.2611 13.4982 10.1985 13.7442L9.446 16.7008C9.42245 16.7931 9.43118 16.8906 9.47075 16.9771C9.51032 17.0637 9.57834 17.1341 9.6635 17.1767L10.2143 17.4517L14.1393 11.8917C14.3477 11.5967 14.6143 11.355 14.9168 11.1767ZM16.5835 10.8333H18.2502V4.16667H16.5835V10.8333Z" fill="#C2C2C2"/>
          }
        </g>
        <defs>
          { isSelected  == "down" &&
            <linearGradient id="paint0_linear_1840_155" x1="10.0002" y1="0.53125" x2="10.0002" y2="17.4993" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2971D7"/>
              <stop offset="1" stopColor="#293AD7" stopOpacity="0.69"/>
            </linearGradient>
          }
          <clipPath id="clip0_1840_155">
            <rect width="20" height="20" fill="white" transform="translate(0.75)"/>
          </clipPath>
        </defs>
      </svg>
    }
    </Box>
  )
}

export { ThumbUp, ThumbDown }
