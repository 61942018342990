/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from "react";
import { createRoot } from "react-dom/client";
import App from "../components/App";
import '../stylesheets/application';
import { GoogleOAuthProvider } from '@react-oauth/google';
require.context('../images', true);

// Had to use turbolinks:load instead of DOMContentLoaded  https://stackoverflow.com/questions/62942573/back-button-displays-blank-page-in-react
document.addEventListener("turbolinks:load", () => {
  const container = document.body.appendChild(document.createElement("div"));
  const root = createRoot(container);
  // React.StrictMode was causing double renders (which led to double http request) 
  root.render(
    <GoogleOAuthProvider clientId="404694637007-tvg4vph9rksfmb0prcsuaa4kjit1bgvi.apps.googleusercontent.com">
        {/* <React.StrictMode> */}
            <App />
        {/* </React.StrictMode> */}
    </GoogleOAuthProvider>
  );
});
