import { styled } from '@mui/system';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ theme, isClicked }) => ({
  textTransform: 'none',
  backgroundColor: 'black',
  width: '100%',
  borderRadius: '30px',
  border: '',
  padding: '8px 14px 8px 14px',
  '&:hover': { backgroundColor: (false) ? 'white' : 'rgba(31,33,35,1.0)', },
}));

const PaywallButton = ({ children, onClick, disabled, isClicked, type = 'buttom'}) => {
  return (
    <StyledButton
      variant="contained"
      disableElevation={true}
      onClick={onClick}
      disabled={disabled}
      isClicked={isClicked}
      type={type}
      // {...props}
    >
      {children}
    </StyledButton>
  )
}

export default PaywallButton
