const isMobileBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(userAgent);
};

const isMobileIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /webOS|iPhone|iPad|iPod/i.test(userAgent);
};

export { isMobileBrowser, isMobileIOS }
