import { Box } from "@mui/system"
import IconButton from '@mui/material/IconButton';
import Regenerate from '../../../javascript/images/Regenerate.png';
import NewButtonDarkMode from '../../../javascript/images/NewButtonDarkMode.png';
import NewButtonLightMode from '../../../javascript/images/NewButtonLightMode.png';
import { isDarkMode } from "../../utils/constants";

const RegenButton = ({transitionFlowRegenAmount, showMore, userData, preventQuestionInput, handleRegenerateSubmit}) => {
  return (
    <Box className="bubble_container" sx={{
      marginTop: '0px',
      opacity: (showMore!=0) ? transitionFlowRegenAmount : showMore,
      transition: 'opacity .7s',
      }}>
        <Box className="more-container">
        <IconButton
          size="small" aria-haspopup="true"
          onClick={() => {
            if (!preventQuestionInput) {
              handleRegenerateSubmit(userData)
            }
          }}
          disabled={false}
        >
          <Box sx={{width: '18px', height: '18px'}}>
            <img src={isDarkMode ? NewButtonDarkMode : NewButtonLightMode} className="img"/> 
          </Box>
        </IconButton>
        </Box>
    </Box>
  )
}

export default RegenButton