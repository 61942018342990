import { Box } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from '../utils/constants';
import Back from '../components/Reusable/Back';

const ContactPage = (props) => {
  const {
    appProps
  } = props

  return (
    <Box sx={{width: '100vw', height: '100vh', backgroundColor: themeColors.backgroundColor}}>
      <Back value={false}/>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={'Contact'}/>
      </Box>
      <Box sx={{width: '100%', height: '100%', display: 'flex'}}>
        <Box sx={{
          display: 'flex', flexDirection: 'column',
          margin: 'auto', marginTop: '100px',
          width: '80%', maxWidth: '600px',
          alignItems: 'center', paddingBottom: '30px',
        }}>
          <p className="feedback-text"
           style={{color: themeColors.color, fontWeight: 500, width: '100%'}}
           >
            We value our users and are always here to help! 
            <br/><br/>
            Whether you have questions, feedback, or need assistance, we're eager to hear from you.
            <br/><br/>
            For general inquiries, please email us at <a href="mailto:chat@inciteai.com" style={{ color: themeColors.linkColor }}>chat@inciteai.com</a>. 
            <br/><br/>
            We aim to respond to all inquiries within 24 hours.
            <br/><br/>
            For professional inquiries or business partnerships, please reach out to our team at <a href="mailto:business@inciteai.com" style={{ color: themeColors.linkColor }}>business@inciteai.com</a>.
            <br/><br/>
            Before reaching out, you may find the answer to your question in our FAQ section.
            <br/><br/>
            You can also follow us on our social media platforms for the latest updates, news, and tips.
            <br/><br/>
            We appreciate your support and look forward to assisting you!
            <br/><br/>
            Best regards,
            <br/><br/>
            The Incite Team
          </p>
        </Box>
      </Box>
    </Box>
  )
}
export default ContactPage
