import { Box, Link } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from '../utils/constants';

const EmailConfirmed = (props) => {
  const {
    appProps
  } = props
  return (
    <Box sx={{width: '100%', height: '100%'}}><Box>
    {/* <Back value={false}/> */}
    <Box sx={{height:'100vh', overflowY: 'auto', backgroundColor: themeColors.backgroundColor}}>
      <Box className="welcome-container" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: '50px'}}>
      <Box className="base-text" sx={{color: themeColors.color, textAlign: 'center', lineHeight: '180%'}}>
        Your email has been confirmed!
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{marginTop: '35px', fontSize: '12px'}} className="base-text sign-text">
          <Link 
            sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
            // onClick={handleSubmit}
            href={"/register"}
          >Sign In
          </Link>
        </Box>
      </Box>
    </Box>
    </Box>
    </Box>
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} />
      {/* // headerDescription={"Confirm Email"} */}
    </Box>
    </Box>
  )
}
export default EmailConfirmed
