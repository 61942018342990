import { useRef, useEffect, useState } from "react"
import { Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import MagnifyingGlass from '../../javascript/images/MagnifyingGlass.png'
import autosize from 'autosize'
import { isMobile } from 'react-device-detect'
import { isDarkMode, themeColors } from "../utils/constants";
import { isMobileBrowser } from "../utils/user_agent";

const Input = (props) => {
  const chatInputStartHeight = 23//26 // must change if changing styling!
  const { onFocus, onBlur, question, setQuestion, onQuestionSubmit, placeholderText, preventQuestionInput, inputPlaceholderText, showSignInButtons } = props
  const questionInputRef = useRef(null)
  const [height, setHeight] = useState(5)

  // const [inputPlaceholderText, setInputPlaceholderText] = useState('')

  const textareaRef = useRef(null);
  useEffect(() => {
    if (questionInputRef.current) {
      autosize(questionInputRef.current);
    }
  }, []);

  // useEffect(() => {
  //   if (showNewUserMessage) {
  //     setTimeout(() => {
  //       const characters = placeholderText.split('')
  //       let curStr = ''
  //       characters.forEach((char, index) => {
  //         setTimeout(() => {
  //           curStr = curStr.concat(char)
  //           setInputPlaceholderText(curStr);
  //         }, index * 50);  // THIS is how quickly the letters print out
  //       })
  //     }, 3800)
  //   } else {
  //     setInputPlaceholderText(placeholderText)
  //     // console.log("Load Returning Data", chatStartingResponse)
  //     // setMessageThreads([chatStartingResponse])
  //   }
  // }, [showNewUserMessage])

  const submitQuestion = () => {
    if (question.length > 0 && !preventQuestionInput) {
      onQuestionSubmit()
      setTimeout(function() {
        questionInputRef.current.style.height = `${23}px`
        setBottomPadding()
      }, 500)
    }
  }
  const setBottomPadding = () => {
    if (questionInputRef.current) {
      // Handle setting the padding (should be 2px for one line of text; 10px otherwise)
      if (parseInt(questionInputRef.current.style.height) > chatInputStartHeight) {
        questionInputRef.current.style.paddingBottom = '10px'
        setHeight(-3)
      } else {
        questionInputRef.current.style.paddingBottom = '2px'
        setHeight(5)
      }
    }
  }

  let classPlaceholderInput = isDarkMode ?
    "placeholder-input dark"
    :
    "placeholder-input"
  
  return (
    <Box style={{
      width: '100%', 
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: themeColors.chatBoxColor,
    }}>
      <Box className="question-input-style">
        <Box className="input-container" sx={{backgroundColor: themeColors.chatBoxColor}}>
          <Box className="inner-input-container" sx={{backgroundColor: themeColors.chatBoxColor}}>
          <Box className="absolute-textarea-container">
          { !showSignInButtons &&
          <>
            <textarea
              className={classPlaceholderInput}
              // style={{backgroundColor: isDarkMode ? '#131521': '#F5F6F7'}}//isDarkMode ? '#0B1A30' : '#F5F9FE'}}
              value={question}
              onChange={e => {
                setBottomPadding()
                setQuestion(e.target.value)
              }}
              onKeyDown={k => {
                // if (!isMobile) { // make sure enter does not submit on mobile
                  if (k.code == "Enter" && !k.shiftKey && !isMobileBrowser()) {
                    k.preventDefault()
                    submitQuestion()
                  }
                // }
              }}
              ref = {questionInputRef} 
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={inputPlaceholderText}//placeholderText}
            />
          
            <Box sx={{
              position: 'absolute', right: '0px', top: `${height}px`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              onClick={submitQuestion}
              >
              <Box sx={{
                width: '32px', height: '32px', borderRadius: '50%',
                // backgroundColor: question.length > 0 ? '#6AABFF' : (isDarkMode ? '#6E6C6C':'#E2E2E2'),
                // backgroundColor: '#178FB5',
                background: question.length > 0 ? (isDarkMode ? 'linear-gradient(180deg, #2971D7 0%, rgba(26, 165, 195, 0.69) 100%)' : 'linear-gradient(180deg, #2979D7 0%, rgba(41, 58, 215, 0.69) 100%)') : 'none',
                '&:hover': {
                  cursor: question.length > 0 ? 'pointer' : ''
                },
                // marginBottom: '5px',
                // marginRight: '1px',
                // marginLeft: '10px',
              }}>
                <Box className="prechat-dot-input-container" sx={{marginLeft: '0px'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 9 12" fill="none">
                    {/* <path d="M8.08167 4.54083L4.54083 1L1 4.54083M4.54083 10.9167V1.09917" stroke="#F7F7F7" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> */}
                    <path d="M8.08167 4.54083L4.54083 1L1 4.54083M4.54083 10.9167V1.09917" stroke={(isDarkMode || question.length > 0) ? "#F7F7F7" : "#C2C2C2"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> 
                  </svg>
                </Box>
              </Box>
            </Box>
          </>
          }

            </Box>


        </Box>
        </Box>

      </Box>
    </Box>
  )
}
export default Input
