const feedbackIncite = (feedback_message) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation ($feedback: String!) {
        feedbackChatMessage(feedback: $feedback) {
          status
        }
      }`,
      variables: {
        feedback: feedback_message,
      },
    })
  };
  let final = fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        return "ERROR"
      } else {
        return data.data
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      return "ERROR"
    })
  return final
}

export default feedbackIncite
