import { Box, Button } from '@mui/material';
import { themeColors } from "../utils/constants";
import InciteDark from '../../javascript/images/InciteDark.png'
import ModalCloseIcon from '../../javascript/images/ModalCloseIcon.png'
import PaywallJoinThousands from '../../javascript/images/PaywallJoinThousands2.png'
import JoinThousandsText from '../../javascript/images/JoinThousandsText.png'
import IncitePlusLogo from '../../../public/IncitePlusLogo.png'
import MarketProLogo from '../../javascript/images/MarketProLogo.png'
import InciteMarketPro from '../../../public/InciteMarketPro.png'
import "../../stylesheets/chat_page.css"
import { useState, useEffect } from 'react';
import { SignInApple, SignInGoogle, SignInUpEmail } from './NewAccountPage';
import PaywallButton from './Mui/PaywallButton';
import { useNavigate } from 'react-router-dom';

const PaywallModal = ({ userData, setUserData, setShowPaywallModal, paywallMessage}) => {

  const navigate = useNavigate()

  const [opacity, setOpacity] = useState(0)
  const handleClose = () => {
    setShowPaywallModal(false); // Hide modal
  };

  const [errors, setErrors] = useState([])
  useEffect(() => {
    setOpacity(1.0)
  }, [])

  const onClickAction = () => {
    navigate('/plan-selection')
  }

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      {/* <div className="modal-content" style={{backgroundColor: "#374860", top: '58%'}}> */}
      <div className="paywall-modal-content" style={{}}>
        
        <Box sx={{position: 'absolute', top: '20px', left: '20px', '&:hover': {cursor: 'pointer'}}} onClick={handleClose}>
          <img src={ModalCloseIcon} style={{height: '16px', width: '16px'}}/> 
        </Box>

        <Box className="base-text" sx={{gap: '0px', marginBottom: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight: '500', fontSize: '36px'}}>
          <img src={MarketProLogo} style={{width: '32px'}} className="logo-img" alt="IncitePlus Logo" />
          {/* <img src={InciteMarketPro} style={{maxHeight: '100%', maxWidth: '220px'}}/>  */}
          <Box className="header-paywall-modal" sx={{fontWeight: '600', color: '#1D1D1F'}}>
            Market Pro
          </Box>
          {/* Plus */}
        </Box>

        {/* <Box className="base-text" sx={{margin: '30px auto', fontWeight: '600', fontSize: '32px'}}> */}
        <Box className="base-text" sx={{margin: '0px auto 0px auto', fontWeight: '500', fontSize: '17px', lineHeight: '150%',}}>
          {/* The best of Incite. */}
          Incredibly powerful. Financial<br/>Market AI.
        </Box>
        <Box className="base-text" sx={{margin: '20px auto 36px auto', fontWeight: '500', fontSize: '16px', color: '#FD0707'}}>
          {paywallMessage}
        </Box>

        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <PaywallButton onClick={() => onClickAction()}>
            <Box sx={{width: '100%', ontWeight: '500', fontSize: '14px', margin: '6px auto',}}>{'Learn More & Upgrade'}</Box>
          </PaywallButton>
        </Box>

        <Box sx={{margin: '36px auto 0px auto', width: '270px'}}>
          <img src={JoinThousandsText} style={{maxWidth: '100%'}}/>
        </Box>

        <Box sx={{marginTop: '2px', width: '100%'}}>
          <img src={PaywallJoinThousands} style={{maxWidth: '100%'}}/>
        </Box>
      

        <Box className="base-text" sx={{marginTop: '18px', fontWeight: '500', fontSize: '22px', lineHeight: '133%'}}>
          A world of world-class<br/>intelligence.
        </Box>

      </div>
    </div>
  )
}

export default PaywallModal
