const EXCHANGE_COUNTRY_CODE_MAPPING = {
  "United States": "US",
  "United Kingdom": "LSE", // Maybe just london?
  "South Korea": "KO",
  "Germany": "BE",
  "Brazil": "SA",
  //"Saudi Arabia": nil, # No Saudi Arabia (There are 300 stocks)
  "Australia": "AU",
  "Hong Kong": "HL",
  "Russia": "MCX",
  "Denmark": "CO",
  "India": "NSE",
  "Argentina": "BA",
  "Israel": "TA",
  //"Singapore": nil, # No Singapore
  //"United Arab Emirates": nil, # No UAE! (there are 92 stocks)
  //"Japan": nil, # No Tokyo Exchange!! (yikes... there are 4218 stocks)
  "Czech Republic": "PR",
  "Canada": "TO",
  "Thailand": "BK",
  "Egypt": "EGX",
  "Botswana": "XBOT",
  "Belgium": "BR",
  //"Qatar": nil, # No Qatar (only 53 stocks)
  "Switzerland": "SW",
  "Turkey": "IS",
  "China": "SHE",
  "Greece": "AT",
  "Chile": "SN",
  "Spain": "MC",
  //"New Zealand": nil, # No New Zealand (there are 311 stocks...)
  "Sweden": "ST",
  "Finland": "HE",
  "Norway": "OL",
  "Taiwan": "TW",
  //"Latvia": nil, # No Latvia (only 24 stocks)
  "Mexico": "MX",
  //"Venezuela": nil, # No Venezuela (only 35 stocks)
  "Romania": "RO",
  "Peru": "LIM",
  "Iceland": "IC",
  "Indonesia": "JK",
  "Ireland": "IR",
  "Jamaica": "JSE", // SHOULD BE "South Africa"
  //"Estonia": nil, #  No Estonia (only 21 stocks)
  //"Lithuania": nil, # No Lithuania (only 23 stocks)
  //"Colombia": nil, # No Colombia (only 2 stocks)
  //"Kuwait": nil, # No Kuwait (only 1 stock)
} 

export default EXCHANGE_COUNTRY_CODE_MAPPING