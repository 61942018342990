import { useState, useEffect, useRef, useCallback } from "react"
import { Box } from '@mui/material';
import ChatBox from "../components/ChatBox";
import { Navbar } from "../components/Navbar";
import Input from "../components/Input";
import Cookies from "universal-cookie";
import { sendChatMessages, regenerateQuestionRecommendations } from "../api/message";
import { questionRegularText, requireLoginMessage, requireCreateAccountMessage, expiredSessionMessage, chatSartingQuestions } from '../utils/constants'
import { useNavigate } from "react-router-dom";
import TermsModal from '../components/TermsModal'
import AddAppHomeModal from '../components/AddAppHomeModal'
import SignUpModalV2 from '../components/SignUpModalV2'
import PaywallModal from '../components/PaywallModal'
import AboutUserModal from '../components/AboutUserModal'
import { isMobileIOS } from "../utils/user_agent";
import { connectWebSocket } from "../components/Chat/websocket";
import { firstUserMessage, askShareMessage, chatStartingResponse, startPlaceholderText, firstUserPlaceholderText } from '../utils/constants'

const ChatPage = (props) => {
  const {
    appProps
  } = props

  const {
    loadingUserData,
    messageThreads, setMessageThreads,
    userData, setUserData,
    hasShownHistory,
    allowSetShit, setAllowSetShit,
    testVar, setTestVar,
    placeholderText,
    setResponseFlow,
    setRegisterNavMessage,
    setHasAcceptedTerms,
    setShowChatShare,
    isNewUUID,
    showSignUpModal, setShowSignUpModal,
    showPaywallModal, setShowPaywallModal,
    paywallMessage,
    inputPlaceholderText, setInputPlaceholderText,
  } = appProps

  const [experienceMessageThreads, setExperienceMessageThreads] = useState([])

  const [showLoadingWaitingMessage, setShowLoadingWaitingMessage] = useState(0)
  const [useFullWidth, setUseFullWidth] = useState(false)
  const [question, setQuestion] = useState('')
  const [buttonOpacity, setButtonOpacity] = useState(1.0)
  const [preventScroll, setPreventScroll] = useState(false)
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [opacity, setOpacity] = useState(1.0)
  const [idSelected, setIdSelected] = useState(null)
  const [showScrollDown, setShowScrollDown] = useState(false)
  // When question is submitted; will prevent user from tapping again
  const [preventQuestionInput, setPreventQuestionInput] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showAddHomeModal, setShowAddHomeModal] = useState(false);
  const [aboutUserModal, setAboutUserModal] = useState(false);
  const [thing, checkThing] = useState(false)
  const [testResponseMessage, setTestResponseMessage] = useState("")
  const subscriptionRef = useRef(null);
  const [secondaryExperienceMessage, setSecondaryExperienceMessage] = useState(null)
  const [isSecondaryExperience, setIsSecondaryExperience] = useState(null)
  const [secondaryExperienceType, setSecondaryExperienceType] = useState(null)
  const [showSignInButtons, setShowSignInButtons] = useState(false)
  const [questionOnDemand, setQuestionOnDemand] = useState(false)

  useEffect(() => {
    if (userData && userData.current_session_id) { // don't connect websocket until user data is set and current_session_id (used for channel)
      // disconnectWebSocket(userData.current_session_id, subscriptionRef, setMessageThreads, showLoadingWaitingMessage, setShowLoadingWaitingMessage, useFullWidth, setUseFullWidth)
      connectWebSocket(setQuestionOnDemand, userData.current_session_id, subscriptionRef, setMessageThreads, showLoadingWaitingMessage, setShowLoadingWaitingMessage, useFullWidth, setUseFullWidth)
      return () => {
        if (subscriptionRef.current) {
          console.log("Called unsubscribe...");
          subscriptionRef.current.unsubscribe(); // Unsubscribe on component unmount
        }
      };
    }
  }, [userData]); // Dependencies of useEffect

  const handleWindowScroll = useCallback(e => {
    setTestVar(window.pageYOffset)
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll, true)
  }, [handleWindowScroll])

  const navRef = useRef(null)
  const chatContainerRef = useRef()
  const chatMessagesContainerRef = useRef()
  const chatRef = useRef(null)

  const navigate = useNavigate()

  const isLoggedIn = !!userData.user_id

  const onFocus = (e) => {
    if (!isLoggedIn) {
      // appProps.setRegisterNavMessage(requireLoginMessage)
      // navigate('/register')
    } else {
      setTimeout(function() {
        setAllowSetShit(true)
      }, 150); //magic number is 150
    }
  }
  
  useEffect(() => {
    if (loadingUserData) {
      setIsSecondaryExperience(null)
    } else {
      if (!isLoggedIn && messageThreads.length == 1) {
      // if (messageThreads.length <= 1 ) {
        setIsSecondaryExperience(true)
        setSecondaryExperienceType("new-user")
        setSecondaryExperienceMessage(firstUserMessage)
      } else if (userData.showAskShareMessage) { // replace with condition for ask-share!
        setIsSecondaryExperience(true)
        setSecondaryExperienceType("ask-share")
        setSecondaryExperienceMessage(askShareMessage)
      } else {
        setSecondaryExperienceType(null)
        setIsSecondaryExperience(false)
        setInputPlaceholderText(startPlaceholderText)
      }
    }
  }, [loadingUserData, userData, messageThreads])

  useEffect(() => {
    if (isSecondaryExperience != null){ 
    if (isSecondaryExperience) {
      setExperienceMessageThreads([  { sender: 'bot', message: '' } ]);
      setTimeout(() => {
        const characters = secondaryExperienceMessage.split('')
        let curStr = ''
        characters.forEach((char, index) => {
          setTimeout(() => {
            curStr = curStr.concat(char)
            setExperienceMessageThreads([
              {
                sender: 'bot',
                message: curStr,
              }
            ]);
          }, index * 20);//30  // THIS is how quickly the letters print out
        })
      }, 500)
      
      setTimeout(() => {
        const characters = firstUserPlaceholderText.split('')
        let curStr = ''
        characters.forEach((char, index) => {
          setTimeout(() => {
            curStr = curStr.concat(char)
            setInputPlaceholderText(curStr);
          }, index * 33);//50  // THIS is how quickly the letters print out
        })
      }, 3100)//4600)

    } else if (messageThreads.length == 1) {
      setMessageThreads([chatStartingResponse])
      setInputPlaceholderText(placeholderText)
    }
    }
  }, [isSecondaryExperience])

  useEffect(() => {
    if (navRef.current && allowSetShit){
      navRef.current.style.top = `${testVar}px`
      // Only if there are messages making up less than 50% of the scroll area to fill do we want to move them down; otherwise the keyboard
      // comes up and the messages move up naturally (not behind header).
      // if (chatMessagesContainerRef.current.scrollHeight < .5*chatContainerRef.current.scrollHeight) {
      if (messageThreads.length < 2 && testVar) {
        chatRef.current.style.top = `${testVar-100}px`
      }
      setTimeout(function() {
        setAllowSetShit(false)
      }, 150);
    }
  }, [allowSetShit, testVar])

  const onBlur = (e) => {
    setTimeout(function() {
      navRef.current.style.top = '0px'
      chatRef.current.style.top = `0px`
      setAllowSetShit(false)
    }, 150);
  }

  const regenButtonRecommendations = async (question=null) => {
    const responseRecommendations = await regenerateQuestionRecommendations(userData, appProps, question)
    const questions_prepopulated = responseRecommendations.messages
    const questions_prepopulated_tickers = responseRecommendations.tickers
    let obj = []
    let tickers
    questions_prepopulated.forEach((message_text, idx) => {
      tickers = questions_prepopulated_tickers[idx] == "" ? "" : JSON.parse(questions_prepopulated_tickers[idx])
      let q = {
        sender: 'human',
        isButton: true,
        message: message_text,
        tickers: tickers,

      }
      if (idx == 0) {
        setResponseFlow(responseFlow => ({
          ...responseFlow,
          question1: [true, q.message, q.tickers, false, ""]
        }))
      } else {
        setResponseFlow(responseFlow => ({
          ...responseFlow,
          question2: [true, q.message, q.tickers, responseRecommendations.isAdvanced, responseRecommendations.advancedType]
        }))
      }
      obj.push([true, message_text, tickers])
    })
    return obj
  }

  useEffect(() => {
    if (thing && testResponseMessage != "") {
      if (messageThreads[messageThreads.length - 1].sender === 'bot') {
      setMessageThreads(messageThreads => [...messageThreads.slice(0,-1), testResponseMessage])
      } else {
      setMessageThreads(messageThreads => [...messageThreads, testResponseMessage])
      }
      checkThing(false)
      setTestResponseMessage("")
    }
  }, [thing, testResponseMessage])

  const onQuestionSubmit = (q=null, messageId=null) => {
    let isExplainWhy = q == "Explain why?"
    
    // If they have asked too many questions; need to require sign up/sign in
    if (userData.requireSignIn) {
      if (userData.uuidHasAccount) {
        appProps.setRegisterNavMessage(requireLoginMessage)
      } else {
        appProps.setRegisterNavMessage(requireCreateAccountMessage)
      }
      navigate('/register')
      return
    }

    // If user is logged in; check that they have a validated email
    if (isLoggedIn && userData.is_email_confirmed == false) {
      navigate('/confirm-email')
      return
    }

    if (preventQuestionInput) {
      return
    }
    if (!preventQuestionInput) {
      setPreventQuestionInput(true)
    }
    let message
    let new_messages
    if (q) {
      setIdSelected(messageId)
      let curThreads = messageThreads//.slice(0,-2)
      message = {
        sender: 'human',
        message: q,
        createdAt: new Date()
      }
      new_messages = [...curThreads, message]
      setIdSelected(messageId)
    } else {
      message = {
        sender: 'human',
        message: question,
        createdAt: new Date()
      }
      // new_messages = [...messageThreads.slice(0,-2), message]
      new_messages = [...messageThreads, message]
    }

    // fire off facebook ask question tracking if not admin user
    if (window.location.hostname !== "localhost" && !userData.is_admin_user) {
      fbq('trackCustom', 'questionAsked');
    }

    setResponseFlow(responseFlow => ({
      ...responseFlow,
      review: false,
      line: false,
      text: false,
      question1: [false, responseFlow.question1[1]],
      question2: [false, responseFlow.question2[1]],
      regen: false
    }))

    let hasReceivedResponse = false
    setButtonOpacity(0.0)
    setPreventScroll(true)
    setMessageThreads(messageThreads => new_messages)
      if (!hasReceivedResponse) {
        setQuestion('')
        setLoadingResponse(true)
        setPreventScroll(false)
      }

    let send_message = message.message
    send_message = send_message + questionRegularText

    let botResponse = sendChatMessages(userData, appProps, send_message, isExplainWhy)
    botResponse.then(botResponse => {
      hasReceivedResponse = true
      let response_message
      let questions_prepopulated
      let questions_prepop_tickers
      // If an error is returned
      if (botResponse && botResponse.error) {
        if (botResponse.error == "Session Expired") {
          setRegisterNavMessage(expiredSessionMessage)
          navigate('/register')
          setMessageThreads(messageThreads => [...messageThreads.slice(0,-1)])
          setResponseFlow(responseFlow => ({
            ...responseFlow,
            question1: [true, message.message, null, false, null],
            question2: [true, chatSartingQuestions[1].message, null, botResponse.isAdvanced, botResponse.advancedType],
            regen: true
          }))
          return
        }
        
        console.log(`Server error: ${botResponse.error}`);
        response_message = {
          createdAt: null,
          message: botResponse.error, // This could be the custom error message you want to show
          sender: "bot",
          tickerDescription: [],
          answeredTickerIds: [],
          logoUrls: [],
        };
        if (botResponse.show_share) {
          setShowChatShare(true)
        }

        if (botResponse.show_sign_up) {
          setTimeout(() => { // Add a small delay to sign up modal
            setShowSignUpModal(true)
          }, 500)
        }

        if (botResponse.show_paywall) {
          setTimeout(() => { // Add a small delay to sign up modal
            setShowPaywallModal(true)
          }, 500)
        }
        
        questions_prepopulated = [message.message]; // Show the same question again...
        questions_prepop_tickers = [""];
      } else if (!botResponse || !botResponse.botMessage) {
        response_message = {
          createdAt: null,
          message: "Please try again, there was an unexpected server error.",
          sender: "bot",
          tickerDescription: [],
          answeredTickerIds: [],
          logoUrls: [],
        }
        questions_prepopulated = [message.message] //Show the same question again...
        questions_prepop_tickers = [""]
      } else {
        response_message = botResponse.botMessage
        questions_prepopulated = botResponse.questionsPrepop
        questions_prepop_tickers = botResponse.questionsPrepopTickers
      }
      setUserData({...userData, ...{show_user_profession: botResponse.showUserProfession, user_profession: botResponse.userProfession, showAskShareMessage: botResponse.showAskShareMessage}})

      if (botResponse.showSaveHomePopup && isMobileIOS()) {
        setShowAddHomeModal(true)
      }

      if (!isLoggedIn && (userData.requireSignIn)) {
        if (userData.uuidHasAccount) {
          appProps.setRegisterNavMessage(requireLoginMessage)
        } else {
          appProps.setRegisterNavMessage(requireCreateAccountMessage)
        }
        // setShowSignInButtons(true) // This will show the buttons to sign in/up
        navigate('/register') 
      }

      // upon response, wait 3 seconds then show response
      let timeout = 50
      setTimeout(() => {
        checkThing(true)
        setTestResponseMessage(response_message)
        setLoadingResponse(false)
        setShowLoadingWaitingMessage(0)
        setOpacity(0.0)
        setOpacity(1.0)
        setButtonOpacity(.999) //So weird.. if I set this back to 1.0 it won't work!!!
      }, timeout)
      // show the question answers:
      
      // Have shown the answer, now show the heart & share:
      timeout = timeout
      setTimeout(() => {
      setResponseFlow(responseFlow => ({
        ...responseFlow,
        review: true
        }))
      }, timeout)

      // Show the Show "Ask Next"
      timeout = timeout + 200
      setTimeout(() => {
        setResponseFlow(responseFlow => ({
          ...responseFlow,
          text: true
        }))
      }, timeout)


      timeout = timeout + 200
      questions_prepopulated.forEach((message_text, idx) => {
        let q = {
          sender: 'human',
          isButton: true,
          message: message_text
        }
        setTimeout(() => {
          if (idx == 0) {
            setResponseFlow(responseFlow => ({
              ...responseFlow,
              question1: [true, q.message, null, false, null]
            }))
          } else {
            setResponseFlow(responseFlow => ({
              ...responseFlow,
              question2: [true, q.message, null, botResponse.isAdvanced, botResponse.advancedType]
            }))
          }
        }, timeout)
      })

      // Show the Show Regenerateive button
      timeout = timeout + 200
      setTimeout(() => {
        setResponseFlow(responseFlow => ({
          ...responseFlow,
          regen: true
        }))
        // ALLOW AGAIN!!!
        setPreventQuestionInput(false)

      }, timeout)
      // Now after the final timeout, show the ask next etc. etc.
    })
  }

  useEffect(() => {
    if (false){//(!isLoggedIn) {      // No ACCEPTED TERMS MODAL RIGHT NOW
      const cookies = new Cookies();
      let hasAcceptedTerms = cookies.get('hasAcceptedTerms');
      // If they have just received answer to first question asked; prompt them.
      if (!hasAcceptedTerms && messageThreads.length > 2 && loadingResponse == false) {
        setShowTermsModal(true);
      }
    }
    if (isLoggedIn && userData && userData.show_user_profession) {
      setAboutUserModal(true)
    }
  }, [isLoggedIn, messageThreads, loadingResponse])

  return (          
    <div role="heading" aria-level="1" aria-label="Chat" style={{width: '100%', height: '100%', backgroundColor: '#FAFAFA'}}>
      {/* CHAT BOX */}
      <Box sx={{ width: '100%'}}>

      {showTermsModal && <TermsModal showTermsModal={showTermsModal} setShowTermsModal={setShowTermsModal} setHasAcceptedTerms={setHasAcceptedTerms}/>}
      {showAddHomeModal && <AddAppHomeModal showAddHomeModal={showAddHomeModal} setShowAddHomeModal={setShowAddHomeModal}/>}
      {showSignUpModal && <SignUpModalV2 userData={userData} setUserData={setUserData} setShowSignUpModal={setShowSignUpModal}/>}
      {showPaywallModal && <PaywallModal userData={userData} setUserData={setUserData} setShowPaywallModal={setShowPaywallModal} paywallMessage={paywallMessage}/>}
      {aboutUserModal && <AboutUserModal aboutUserModal={aboutUserModal} setAboutUserModal={setAboutUserModal} userData={userData} setUserData={setUserData}/>}

      <ChatBox 
        preventScroll={preventScroll}
        setPreventScroll={setPreventScroll}
        messageThreads={messageThreads} 
        experienceMessageThreads={experienceMessageThreads}
        setQuestion={setQuestion} 
        onQuestionSubmit={onQuestionSubmit} 
        buttonOpacity={buttonOpacity}
        idSelected={idSelected}
        loadingResponse={loadingResponse}
        opacity={opacity}
        userData={userData} setUserData={setUserData}
        appProps={appProps}
        chatRef={chatRef}
        chatContainerRef={chatContainerRef}
        chatMessagesContainerRef={chatMessagesContainerRef}
        showScrollDown={showScrollDown}
        setShowScrollDown={setShowScrollDown}
        hasShownHistory={hasShownHistory}
        regenButtonRecommendations={regenButtonRecommendations}
        preventQuestionInput={preventQuestionInput}
        setPreventQuestionInput={setPreventQuestionInput}
        showLoadingWaitingMessage={showLoadingWaitingMessage}
        setShowLoadingWaitingMessage={setShowLoadingWaitingMessage}
        useFullWidth={useFullWidth}
        isSecondaryExperience={isSecondaryExperience}
        secondaryExperienceType={secondaryExperienceType}
        isNewUUID={isNewUUID}
        loadingUserData={loadingUserData}
        setIsSecondaryExperience={setIsSecondaryExperience}
        showSignInButtons={showSignInButtons}
        questionOnDemand={questionOnDemand}
        setQuestionOnDemand={setQuestionOnDemand}
        />
      </Box>
    {/* NAVBAR */}
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}} 
    ref={navRef}
    >
      { (!loadingUserData) &&
        <Navbar appProps={appProps} allowAbout={true} showSubscriptionStatus={true} onQuestionSubmit={onQuestionSubmit}/>
      }
    </Box>
      <Box sx={{width: '100%', marginBottom: '10px'}}>
        <Input showSignInButtons={showSignInButtons} onFocus={onFocus} onBlur={onBlur} question={question} setQuestion={setQuestion} onQuestionSubmit={onQuestionSubmit} placeholderText={placeholderText} preventQuestionInput={preventQuestionInput} inputPlaceholderText={inputPlaceholderText}/>
        {/* <PrechatVersion/> */}
      </Box>
    </div>
  )
}

export default ChatPage
