import { Box } from "@mui/system"
import Incite from '../../javascript/images/Incite.png'
import InciteDark from '../../javascript/images/InciteDark.png'
import { isDarkMode, themeColors } from "../utils/constants";

const Logo = (props) => {
  return ( 
    <Box className="logo-container">
      <Box className="logo-logo">
        <img src={isDarkMode ? InciteDark : Incite} style={{maxHeight: '100%', maxWidth: '100%'}}/> 
      </Box>
      {/* <Box className="logo-advisor-text" sx={{color: themeColors.color}}>
        Advisor
      </Box> */}
    </Box>
  ) 
}
export default Logo
