import { Box, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import PoweredByIncite from '../components/PoweredByIncite';
import { isDarkMode, themeColors } from '../utils/constants';
const PriorityPage = (props) => {
  const {
    appProps
  } = props
  return (
    <Box sx={{width: '100vw', height: '100vh', backgroundColor: themeColors.backgroundColor}}>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} allowAbout={true}/>
      </Box>
      <Box sx={{width: '100%', height: '100%', display: 'flex'}}>
        <Box sx={{
          display: 'flex', flexDirection: 'column',
          margin: 'auto', alignItems: 'center',
          width: '100%', paddingBottom: '30px',
        }}>
          <Box sx={{marginTop: '30px'}}>
          <img src={isDarkMode ? TimerDark : Timer} style={{maxHeight: '100%', width: '28px'}}/> 
          </Box>
          <Box className="base-text" sx={{fontSize: '16px', fontWeight: 500, margin: '10px', color: themeColors.color}}>29min</Box>
          <Box className="base-text" sx={{fontSize: '16px', fontWeight: 700, margin: '10px', color: themeColors.color}}>Looks like Incite is a little popular right now..</Box>
          <Box className="base-text" sx={{fontSize: '14px', margin: '10px', textAlign: 'center', lineHeight: '24px', color: themeColors.color}}>Please try again shortly or<br></br>consider subscribing for Priority Access</Box>
          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '20px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '400px',
            maxWidth: '100%',
          }} 
          onClick={() => 
          navigate('/chat')
          }>
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontWeight: '400', alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '2px',
              color: '#F2F2F2',
            }}>
              Priority Access
            </Box>
          </Button>
        </Box>
      </Box>
      <PoweredByIncite/>
    </Box>
  )
}
export default PriorityPage
