import { Box } from '@mui/material';
import SignInLogo from '../../javascript/images/SignInLogo.png'
import "../../stylesheets/chat_page.css"
import { useState, useEffect } from 'react';
import { SignInApple, SignInGoogle, SignInUpEmail } from './NewAccountPage';

const SignUpModalV2 = ({ userData, setUserData, setShowSignUpModal}) => {
  const [opacity, setOpacity] = useState(0)
  const handleClose = () => {
    setShowSignUpModal(false); // Hide modal
  };

  const [errors, setErrors] = useState([])
  useEffect(() => {
    setOpacity(1.0)
  })

  return (
    <div className="modal" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      <div className="modal-content" style={{
        background: "linear-gradient(180deg, #ADDCEE 0%, #fff 100%)", top: '50%',
        paddingTop: '24px',
      }}>
        <Box className="base-text" sx={{fontSize: '12px', color: '#2971D7', fontWeight: '500', position: 'absolute', top: '18px', left: '20px', '&:hover': {cursor: 'pointer'}}} onClick={handleClose}>
          Close
        </Box>
        <img src={SignInLogo} style={{maxHeight: '100%', maxWidth: '28px', marginBottom: '20px'}}/> 
        <Box className="base-text" sx={{
          paddingTop: '5px', 
          paddingBottom: '12px', 
          fontWeight: 'bold', fontSize: '30px',
          background: "linear-gradient(180deg, #004D95 0%, #0582F6 100%)", WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
        }}>Sign In</Box>
        <Box className="base-text" sx={{
          marginTop: '4px', 
          paddingTop: '5px', 
          paddingBottom: '4px', 
          fontWeight: 'bold', fontSize: '20px',
          background: "linear-gradient(180deg, #004D95 0%, #0582F6 100%)", WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
        }}>to continue for free.</Box>
        <Box sx={{
          marginTop: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '12px', gap: '4px',
        }}>
          <Box sx={{marginLeft: '-10px', color: 'rgb(255,0,0,.6)', textAlign: 'left'}}>
            <ul>{errors.map((error) => { return <li key={error}>{error}</li> })}</ul>
          </Box>
          <SignInApple overrideDarkMode={true} userData={userData} setUserData={setUserData} setErrors={setErrors} setShowSignUpModal={setShowSignUpModal}/>
          <SignInGoogle overrideDarkMode={true} userData={userData} setUserData={setUserData} setErrors={setErrors} setShowSignUpModal={setShowSignUpModal}/> 
          <SignInUpEmail overrideDarkMode={true} userData={userData} setUserData={setUserData} setErrors={setErrors} setShowSignUpModal={setShowSignUpModal}/>
        </Box>
        <Box className="base-text" sx={{
          fontStyle: 'italic', lineHeight: '150%', fontWeight: '600', marginTop: '36px', marginBottom: '8px', fontSize: '16px',
          background: "linear-gradient(270deg, #004D95 0%, #0582F6 100%)", WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'
        }}>Trusted by thousands of users worldwide.</Box>
      </div>
    </div>
  )
}

export default SignUpModalV2
