import { Box, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { isDarkMode, themeColors } from '../../utils/constants'

const ScrollDown = (props) => {
  const { executeScroll } = props
  return (
    <Box className="scroll-down-container">
      <Box sx={{
        width: '100%', 
        marginBottom: '14px',
        marginTop: '0px',
        display: 'flex', alignItems: 'center', justifyContent: 'center'
      }}>
        <Tooltip title="Scroll Down">
          <IconButton
            size="small" aria-haspopup="true"
            onClick={executeScroll}
          >
            <Box sx={{padding: '4px 12px 0px 12px', borderRadius: '20px',
              backgroundColor: themeColors.showHistoryContainerColor
            }}>
              <ArrowUpwardIcon sx={{fontSize: '18px', color: themeColors.showHistoryColor, transform: 'rotate(180deg)'}}/>
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
export default ScrollDown
