import { Box } from "@mui/system"
import Incite from '../../javascript/images/Incite.png'
import InciteDark from '../../javascript/images/InciteDark.png'
import { isDarkMode, themeColors } from "../utils/constants";

const PoweredByIncite = () => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center',
      position: 'fixed',
      bottom: '35px',
      width: '100%',
    }}>
      <Box className="logo-footer-container">
        <img src={isDarkMode ? InciteDark : Incite} alt="INCITE" style={{maxHeight: '100%', maxWidth: '100%'}}/> 
      </Box>
    </Box>
  )
}
export default PoweredByIncite
