import { Box } from '@mui/material';
import TermsOfUse from "../components/TermsOfUse";
import { Navbar } from "../components/Navbar";
import { themeColors } from "../utils/constants";

const TermsOfUsePage = (props) => {
  const {
    appProps
  } = props
  return (
    <Box sx={{width: '100%', height: '100%', backgroundColor: themeColors.backgroundColor}}>
      <Box>
        <TermsOfUse/>
      </Box>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={'Terms of Use'}/>
      </Box>
    </Box>
  )
}
export default TermsOfUsePage
