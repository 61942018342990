import { Box } from "@mui/material"
import '../../../stylesheets/index.css'
import '../../../stylesheets/chat_area.css'
import { isDarkMode } from "../../utils/constants";

const ResponseWaiting = () => {
  let leftChatClass = isDarkMode ?
    "left-chat-box-waiting dark chat-message dark"
    :
    "left-chat-box-waiting chat-message"
  let style = 'loading'
  return(
    <Box sx={{marginTop: '18px'}}>
      <div 
        style={{
          position: 'relative',
          maxWidth: '30em',
          paddingRight: '2px',
          width: '34px',
          height: '18px',
          paddingBottom: '10px',
        }}
        className={leftChatClass}
        >
        <Box className={`incite_text ${style}`} sx={{
          color: isDarkMode ?  '#FFFFFF' : 'rgba(0, 0, 0, 0.3)',
          textAlign: 'left', fontSize: '30px', lineHeight: '18px'}}>
          {""}
        </Box>
      </div>
    </Box>
  )
}

const ResponseWaitingSecond = (props) => {
  const {
    text,
    sx,
    hideDotDotDot
  } = props
  let leftChatClass = isDarkMode ?
    "left-chat-box-waiting dark chat-message dark"
    :
    "left-chat-box-waiting chat-message"
  let style = hideDotDotDot ? '' : 'loading'
  return(
    <Box sx={{marginTop: '18px'}}>
      <div 
        style={{
          position: 'relative',
          maxWidth: '30em',
          paddingRight: '2px',
          width: '140px',
          ...sx,
        }}
        className={leftChatClass}
        >
        <Box className={`incite_text ${style}`} sx={{
          textAlign: 'left', fontSize: '14px', lineHeight: '18px'}}>
          {text}
        </Box>
      </div>
    </Box>
  )
}


const OnDemandWaiting = (props) => {
  const {
    text,
    sx,
    hideDotDotDot
  } = props

  // let leftChatClass = isDarkMode ?
  //   "on-demand-waiting"
  //   :
  //   "on-demand-waiting"
  let style = hideDotDotDot ? '' : 'loading'
  return(
    <Box sx={{marginTop: '36px'}}>
      <div 
        style={{
          position: 'relative',
          maxWidth: '30em',
          paddingRight: '2px',
          width: '140px',
          // background: 'linear-gradient(0deg, #2971D7 0%, #8A6FB7 100%)',
          animation: '3.0s on-demand-waiting-animation infinite',
          ...sx,
        }}
        // className={leftChatClass}
        >
        <Box className={`incite_text ${style}`} sx={{
          textAlign: 'left', fontSize: '14px', lineHeight: '18px',
          color: isDarkMode ? 'white' : 'black',
          }}>
          {text}
        </Box>
      </div>
    </Box>
  )
}

export { ResponseWaiting, ResponseWaitingSecond, OnDemandWaiting }
