import { Box } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from "../utils/constants";
import Back from '../components/Reusable/Back';

const FAQsPage = (props) => {
  const {
    appProps
  } = props
  return (
    <Box sx={{width: '100%', height:'100vh', overflowY: 'auto', backgroundColor: themeColors.backgroundColor}}>
      <Back value={false}/>
      <Box sx={{marginTop: '100px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Box sx={{color: themeColors.color, lineHeight: '166%', margin: '0px 40px', width: '100%', maxWidth: '700px'}}>
        <p>
        <b>Q: What do "days" refer to in your time scales?</b>
        <br/>A: "Days" refers to a minimum of 1.5 days and a maximum of 10 days. Similarly, "weeks" imply up to a month and a half (6 weeks).
        </p><p>
        <b>Q: How accurate is the information provided?</b>
        <br/>A: While we strive for utmost accuracy and utilize cutting-edge technology, there may occasionally be some inaccuracies due to AI miscommunication, among other factors.
        </p><p>
        <b>Q: What range of financial data do you provide insights on?</b>
        <br/>A: Currently, we offer insights on around 10,000 stocks, 200 cryptocurrencies, and 100 foreign exchange currencies.
        </p><p>
        <b>Q: Is there a cost to use your service?</b>
        <br/>A: Currently, our service is free and self-funded. We might introduce subscription fees in the future to support growth and maintain service quality.
        </p><p>
        <b>Q: How often do your algorithms update?</b>
        <br/>A: Our algorithms update hourly. We are prioritizing efforts to increase this frequency for more up-to-date insights.
        </p><p>
        <b>Q: How volatile are short-term time scales?</b>
        <br/>A: Short-term timescales tend to be more volatile, experiencing rapid changes, often due to day trading activities.
        </p><p>
        <b>Q: Is there an Incite mobile app?</b>
        <br/>A: An app is currently in development and is expected to be released by the end of this year.
        </p>
      </Box>
      </Box>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={'FAQs'}/>
      </Box>
    </Box>
  )
}
export default FAQsPage
