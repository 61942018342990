import { Box } from '@mui/material';
import NewAccountPage from "../components/NewAccountPage";
import { Navbar } from "../components/Navbar";
import { useState, useEffect } from 'react';

const RegisterPage = (props) => {
  const {
    appProps
  } = props
  const { loadingUserData, userData } = appProps
  const [signInOrUp, setSignInOrUp] = useState(null)
  const [signInType, setSignInType] = useState(null)
  useEffect(() => {
    if (!loadingUserData) {
      if (userData.uuidHasAccount) {
        setSignInOrUp("sign-in")
        setSignInType("sign-in-email")
      } else {
        setSignInOrUp("sign-up")
        setSignInType("sign-up-email")
      }
    }
  }, [loadingUserData, userData])
  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <Box>
        { (signInType != null) &&
        <NewAccountPage
          appProps={appProps}
          signInOrUp={signInOrUp}
          setSignInOrUp={setSignInOrUp}
          signInType={signInType}
          setSignInType={setSignInType}
        />
        }
      </Box>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={signInOrUp == "sign-in" ? "Sign in" : "Sign up"}/>
      </Box>
    </Box>
  )
}
export default RegisterPage
