import { Box } from "@mui/system"
import CheckIcon from '@mui/icons-material/Check';
import { themeColors } from "./constants";

const CopyContainer = () => {
  return (
    <Box sx={{
      position: 'absolute',
      left: '0px', right: '0px', bottom: '0px', top: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Box sx={{
      borderRadius: '7px',
      background: themeColors.copyContainer,
      padding: '2px 18px 2px 18px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }}>
      <p className="base-text" style={{
        fontSize: '11px', color: themeColors.copyText, 
        fontWeight: 500,
      }}>
        Copied
      </p>
      <CheckIcon sx={{marginLeft: '5px', fontSize: '16px', color: themeColors.copyText}} />
      </Box>
    </Box>
  )
}


export default CopyContainer