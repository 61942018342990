import { Box, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { themeColors } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Unsubscribed = (props) => {
  const { appProps } = props
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState(null)

  const query = new URLSearchParams(location.search);
  const serverError = query.get("error");

  const handleSubmit = (event) => {
    event.preventDefault()
    navigate('/chat')
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}><Box>
    <Box sx={{height:'100vh', overflowY: 'auto', backgroundColor: themeColors.backgroundColor}}>
      <Box className="welcome-container" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: '50px'}}>
      <Box className="base-text" sx={{color: themeColors.color, textAlign: 'center', lineHeight: '180%', marginBottom: '20px',}}>
        <Box sx={{margin: '20px auto', width: '450px', color: 'rgb(255,0,0,.6)'}}>
          {errorMessage}
        </Box>
        { serverError ?
          "There was a server error; please reach out to help@inciteai.com"
        :
          "You have Unsubscribed from receiving these Incite emails."
        }
      </Box>
      <Box sx={{marginTop: '10px',}}>
        <Button variant="contained" className="i_understand_button" sx={{
          textTransform: 'none',
          borderRadius: '24px',
          background: themeColors.buttonColor,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
          marginTop: '0px',
        }} 
        onClick={handleSubmit}
        >
        <Box className="base-text i_understand_text" sx={{
          display: 'flex',
          fontWeight: '400', alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '0px',
          color: '#F2F2F2',
        }}>
          Return to Chat
        </Box>
        </Button>
      </Box>
    </Box>
    </Box>
    </Box>
    <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
      <Navbar appProps={appProps} headerDescription={"Unsubscribe"} />
    </Box>
    </Box>
  )
}
export default Unsubscribed
