import { Box } from '@mui/material';
// import BackArrow from '../../../javascript/images/BackArrow.png'
import BackArrowBlue from '../../../javascript/images/BackArrowBlue.png'
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

const Back = (props) => {
  const navigate = useNavigate()
  const {
    setMethod, value
  } = props
  return (
  <Box className="about-back-container">
    <IconButton
      onClick={() => {
        if (setMethod) {
          setMethod(value)
        } else {
          navigate(-1)
        }
      }}
      size="small" aria-haspopup="true"
    ><img src={BackArrowBlue} style={{maxHeight: '100%', height: '20px'}}/> 
    </IconButton>
  </Box>
  )
}
export default Back
