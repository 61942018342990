import { Box } from '@mui/material';
import PrivacyPolicy from "../components/PrivacyPolicy";
import { Navbar } from "../components/Navbar";
import { isDarkMode, themeColors } from "../utils/constants";

const PrivacyPolicyPage = (props) => {
  const {
    appProps
  } = props
  return (
    <Box sx={{width: '100%', height: '100%', backgroundColor: themeColors.backgroundColor}}>
      <Box>
        <PrivacyPolicy />
        </Box>
        <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}} //ref={navRef}
        >
        <Navbar appProps={appProps} headerDescription={'Privacy Policy'} //useLightMode={true}
        />
      </Box>
    </Box>
  )
}
export default PrivacyPolicyPage
