import { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
const DisclosuresWrapper = (props) => {
  const {
    Content
  } = props

  const [showScrollDown, setShowScrollDown] = useState(false)
  useEffect(() => {
    setShowScrollDown(true)
  }, []) //only run once

  const myRef = useRef()
  const containerRef = useRef()

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const handleScroll = useCallback(e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) { 
      setShowScrollDown(false)
    } else {
      if (!showScrollDown && e.target.scrollTop/e.target.scrollHeight < .80) { setShowScrollDown(true) }
    }
  }, [])

  useEffect(() => {
    if (myRef.current) {
      myRef.current.addEventListener("scroll", handleScroll)
    }
  }, [handleScroll])
  

  return (
    <Content
      executeScroll={executeScroll}
      showScrollDown={showScrollDown}
      containerRef={containerRef}
      myRef={myRef}
    />
  )

}
export { DisclosuresWrapper }