const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
const get_message_date = (messageThreads, countSinceShowMessageTime, idx) => {
  const minutesBetweenShowTime = 30
  let show_message_time
  let date_value
  let daysDiff
  let messageDate
  const curDate = new Date()

  let curDateStr = curDate.toLocaleTimeString().split(' ')
  curDateStr = curDateStr[0].split(':')[0]+':'+curDateStr[0].split(':')[1] +' '+ curDateStr[1]
  date_value = "Today " + curDateStr

  if (messageThreads[idx].createdAt) {
    
    messageDate = new Date(messageThreads[idx].createdAt);
    daysDiff = (curDate - messageDate) / (1000 * 60 * 60 * 24)
    let str = messageDate.toLocaleTimeString().split(' ')
    str = str[0].split(':')[0]+':'+str[0].split(':')[1] +' '+ str[1]
    if (daysDiff >= 6) {
      date_value = messageDate.toDateString()
    } else if (daysDiff >= 2) {
      date_value = days[ messageDate.getDay() ] + ' ' + str
    } else if (messageDate.getDay() != curDate.getDay()) {
      date_value = "Yesterday " + str
    } else {
      date_value = "Today " + str
    }
    if (idx == 0 || !messageThreads[idx-1].createdAt) {
      show_message_time = true
    } else {
      const d2 = new Date(messageThreads[idx-1].createdAt);
      if ((messageDate-d2)/1000 > 60*minutesBetweenShowTime) {
        show_message_time = true
      }
    }
  }

  // NEED to fix this so that it only looks at the most recent and places a timestamp 8 above
  countSinceShowMessageTime = show_message_time ? 0 : countSinceShowMessageTime += 1
  if (countSinceShowMessageTime >= 10) {
    show_message_time = true
    countSinceShowMessageTime = 0
  }
  return {date_value, show_message_time}
}
export default get_message_date
