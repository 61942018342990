import { Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { themeColors } from "../utils/constants";
import InciteDark from '../../javascript/images/InciteDark.png'
import "../../stylesheets/chat_page.css"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import { pofessionsList, customSelectThemeModal } from '../utils/constants';
import CountrySelector from '../utils/CountrySelector';
import EXCHANGE_COUNTRY_CODE_MAPPING from '../utils/CountryExchanges';

const AboutUserModal = ({aboutUserModal, setAboutUserModal, userData, setUserData}) => {

  const [profession, setProfession] = useState('');
  const [country, setCountry] = useState('')
  const [otherProfession, setOtherProfession] = useState('');
  const [countryExchange, setCountryExchange] = useState(Object.keys(EXCHANGE_COUNTRY_CODE_MAPPING)[0]); // Default start with United states

  useEffect(() => {
    if (userData.user_account_info && userData.user_account_info.country) {
      setCountry(userData.user_account_info.country)
    }
  }, [userData.user_account_info])

  const handleChangeProfession = (event) => {
    setProfession(event.target.value);
    if(event.target.value !== 'Other') {
      setOtherProfession('');
    }
  };

  const handleChangeExchange = (event) => {
    setCountryExchange(event.target.value);
  };

  const handleOtherChange = (event) => {
    setOtherProfession(event.target.value);
  };
  const handleAccept = () => {
    setAboutUserModal(false); // Hide modal
    const userProfession = profession === 'Other' ? `Other - ${otherProfession}` : profession
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `mutation ($profession: String!, $country: String!, $countryExchange: String!) {
          userProfessionCountry(profession: $profession, country: $country, countryExchange: $countryExchange) {
            status
          }
        }`,
        variables: {
          profession: userProfession,
          country: country,
          countryExchange: countryExchange,
        },
      })
    };
  
    try {
      let final = fetch('/graphql', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          console.log("GraphQL Errors:", data.errors);
        } else {
          setUserData({
            ...userData, 
            ...{
              user_profession: userProfession, 
              show_user_profession: false,
              user_country: country,
            },
            user_account_info: {
              ...userData.user_account_info,
              country: country,
              selected_profession: userProfession,
            }
          })
        }
        return data.data;
      })
    } catch(e) {
      console.log("What was the error?", e)
    }
  };

  console.log("ok??", userData.user_account_info, userData.user_account_info.selected_country_exchange, !userData.user_account_info.selected_country_exchange)

  return (
    <ThemeProvider theme={customSelectThemeModal}>
    <div className="modal">
      <div className="modal-content" style={{backgroundColor: "#374860"}}>
        <img src={InciteDark} style={{maxHeight: '100%', maxWidth: '90px'}}/> 
        { (!userData.user_account_info || !userData.user_account_info.selected_profession) &&
          <>
          <Box className="h2-terms-modal" sx={{marginTop: '24px', fontSize: '14px'}}>
            Which industry do you work in?
          </Box>
          <Box sx={{marginTop: '15px', marginBottom: '20px'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Profession</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={profession}
                label="Profession"
                onChange={handleChangeProfession}
                style={{color: 'white'}}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: 'rgba(55,55,55)',
                    },
                  },
                }}
              >
                {pofessionsList.map((prof) => (
                  <MenuItem key={prof} value={prof} style={{backgroundColor: 'rgba(55,55,55)', color: 'white'}}>
                    {prof}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {profession === 'Other' && (
            <TextField
              label="Specify Profession"
              value={otherProfession}
              onChange={handleOtherChange}
              fullWidth
              sx = {professionTextStyles}
            />
          )}
          </>
        }
        { (!userData.user_account_info || !userData.user_account_info.selected_profession) && //country is already set, but if this is first time, will just check the industry
          <>
          <Box className="h2-terms-modal" style={{marginTop: '24px', fontSize: '14px'}}>
            Which country do you live in?
          </Box>
          <Box sx={{marginTop: '15px', marginBottom: '20px'}}>
            <FormControl fullWidth>
              <CountrySelector country={country} setCountry={setCountry}/>
            </FormControl>
          </Box>
          </>
        }

        { (!userData.user_account_info || !userData.user_account_info.selected_country_exchange) &&
        // {/* { (!['', "United States"].includes(country)) && */}
        // {/* { (![''].includes(country)) && */}
          <>
            <Box className="h2-terms-modal" style={{marginTop: '24px', fontSize: '14px'}}>
              Which stock exchange are you on?
            </Box>
            <Box sx={{marginTop: '20px', marginBottom: '20px'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Exchange Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={countryExchange}
                  label="CountryExchange"
                  onChange={handleChangeExchange}
                  style={{color: 'white'}}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: 'rgba(55,55,55)',
                      },
                    },
                  }}
                >
                  {Object.keys(EXCHANGE_COUNTRY_CODE_MAPPING).map((k) => (
                    <MenuItem key={k} value={k} style={{backgroundColor: 'rgba(55,55,55)', color: 'white'}}>
                      {k} ({EXCHANGE_COUNTRY_CODE_MAPPING[k]})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box className="h2-terms-modal" style={{marginTop: '30px', fontSize: '12px', fontStyle: 'italic'}}>
                You can update any time in<br/>your account settings
              </Box>

            </Box>
          </>
        }
        <Box sx={{marginTop: '0px',}}>
          <Button variant="contained" className="i_understand_button" sx={buttonStyles}
          onClick={() => {
            if (!(!profession || (profession === 'Other' && !otherProfession)) && country != '') {
              handleAccept()
            }
          }}
          >
          <Box className="base-text i_understand_text" sx={submitStyles}>
            Submit
          </Box>
          </Button>
        </Box>
      </div>
    </div>
    </ThemeProvider>
  )
}

export default AboutUserModal

const buttonStyles = {
  width: '245px',
  height: '34px',
  textTransform: 'none',
  borderRadius: '24px',
  background: themeColors.buttonColor,
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
  marginTop: '0px',
}

const submitStyles = {
  display: 'flex', fontWeight: '400', alignItems: 'center',
  justifyContent: 'center', textAlign: 'center', marginTop: '0px', color: '#F2F2F2', fontSize: '14px',
}

const professionTextStyles = {
  marginTop: '10px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: 'white',
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
  }
}
