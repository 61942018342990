import { Box, Button, TextField, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { isProduction } from '../utils/constants'
import { isDarkMode, themeColors } from "../utils/constants";
import CircularProgress from '@mui/material/CircularProgress';

const SignUp = (props) => {
  const navigate = useNavigate()

  const {
    appProps
  } = props

  const {
    userData,
    setUserData
   } = appProps

  const [isChecked, setIsChecked] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const [selectedCreateAccount, setSelectedCreateAccount] = useState(true);

  const [signUpParams, setSignupParams] = useState({
    full_name: isProduction ? "" : "a u",
    email: isProduction ? "" : "andrew@metpro.co",
    password: isProduction ? "" : "12345678"
  })
  const [errors, setErrors] = useState([])
  const [emailSelected, setEmailSelected] = useState(0)
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isLoading) { // "disabled"
      return
    }
    if (!agreeTerms) {
      setErrors(["Please agree to the Terms and Conditions below."])
      return 
    }
    console.log("Set is loading?")
    setIsLoading(true);  
    let user = {
      full_name: signUpParams.full_name,
      email: signUpParams.email,
      password: signUpParams.password,
      subscribed_marketing: isChecked,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({user: user})
  };
  fetch('/create_account', requestOptions)
    .then(response => {
      return (response.json())
    })
    .then(data => {
      if (data.error_message) {
        setErrors(data.error_message)
      } else {
        setUserData({...userData, ...data, requireSignIn: false, }) //set back to false if they were required to sign in immediately (same IP address user)
        // Now that we have callback; fire off facebook signup tracking:
        if (window.location.hostname !== "localhost") {
          fbq('track', 'SignUp');
        }
        navigate('/confirm-email')
      }
    })
    .catch(error => {
      console.log('api errors:', error)
      setErrors(error[0])
    })
    .finally(() => {
      setIsLoading(false); // Reset the loading state regardless of success or failure
    });
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setSignupParams(signUpParams => ({
      ...signUpParams,
      [name]: value,
    }))
  };

  // FOr the flow of this:
  // https://medium.com/swlh/react-reactions-cfdde7f08dff


  return (
  <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Box sx={{width: '94%', maxWidth: '350px'}}>

    { !selectedCreateAccount ?
      <Box sx={{marginTop: '12px', width: '100%', textAlign: 'center', display: 'flex',  justifyContent: 'center',}}>
        <Button 
          variant="contained"
          className="i_understand_button"
          sx={{
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
          }}
          onClick={() => setSelectedCreateAccount(true)}
        >
          <Box className="base-text i_understand_text" sx={{
            display: 'flex',
            fontWeight: '500', 
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            // marginTop: '2px',
            color: '#F2F2F2',
            fontSize: '18px',
          }}>
            Create Account
          </Box>
        </Button>
      </Box>
    :
      <form autoComplete="off" onSubmit={handleSubmit} >
        { (errors.length > 0) ?
        <Box sx={{color: 'rgb(255,0,0,.8)', marginBottom: '42px'}}>
          <ul>{errors.map((error) => {
            return <li key={error}>{error}</li>
            })}
          </ul>
        </Box>
        :
        <Box sx={{marginBottom: '12px'}}></Box>
        }
        
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <TextField 
            sx={textFieldStyles}
            inputProps={{ required: true}} 
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
            autoComplete="off"
            value={signUpParams.full_name}//{"a u"}
            onChange={handleChange}
            name="full_name"
            label="Full Name"/>
          
          <Box sx={{position: 'relative', width: '100%', display: 'flex', flexDirection: 'column'}}>
          <TextField 
            sx={textFieldStyles}
            inputProps={{ required: true}} 
            // required={true} 
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color,}}} 
            type="email" 
            autoComplete="off"
            value ={signUpParams.email}//{"andrew@metpro.co"}
            onChange={(e) => {
              handleChange(e)
              setEmailSelected(0.99)
            }}
            name="email"
            label="Email Address"
            onBlur={() => setEmailSelected(0)}
            />
          
            <Box variant="caption" sx={{
              opacity: emailSelected,
              transition: 'opacity 0.5s',
              pointerEvents: emailSelected > 0 ? 'auto' : 'none',
              position: 'absolute', top: '-64px', left: '6px', 
              backgroundColor: 'rgba(100,100,100,1.0)',
              fontFamily: 'Montserrat', fontWeight: '400',
              color: '#fff', 
              padding: '10px',
              borderRadius: '6px',
              fontSize: '13px',
              zIndex: 99}}>
                A validation link will be sent to this email.<br/>Please ensure it's valid.
            </Box>

          </Box>

          <TextField 
            sx={textFieldStyles}
            inputProps={{ minLength: 8, required: true }}
            InputLabelProps={{style: {fontFamily: 'Montserrat', fontWeight: '400', color: themeColors.color}}} 
            type="password" 
            value={signUpParams.password}//{"12341234"}
            onChange={handleChange}
            autoComplete="off"
            name="password"
            label="Password"/>
          
          <Box sx={{
            fontFamily: 'Montserrat', fontWeight: '400',
            marginTop: '10px', marginBottom: '8px', width: '100%',
            color: themeColors.inciteTextColor, fontSize: '12px', lineHeight: '150%',
          }}>
            <input 
              type="checkbox"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
            />
            &nbsp;I agree to the <Link 
              sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '13px'}} 
              href="https://inciteai.com/terms-and-conditions" target = "_blank"
            >Terms and Conditions
            </Link> and <Link 
              sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '13px'}} 
              href="https://inciteai.com/privacy-policy" target = "_blank"
            >Privacy Policy
            </Link> and to receive important account related emails from Incite.
          </Box>
          <Box sx={{
            fontFamily: 'Montserrat', fontWeight: '400',
            marginTop: '10px', marginBottom: '32px', width: '100%',
            color: themeColors.inciteTextColor, fontSize: '12px', lineHeight: '150%',
          }}>
            <input 
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            &nbsp;Yes, I'd like to receive news and updates from Incite&nbsp;
          </Box>

          <Box sx={{marginTop: '0px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{width: '100%', textAlign: 'center', display: 'flex',  justifyContent: 'center',}}>
              <Box>
                <Button 
                  variant="contained" 
                  className="i_understand_button" 
                  sx={{
                    textTransform: 'none',
                    borderRadius: '24px',
                    background: themeColors.buttonColor,
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
                    width: '250px',
                  }} 
                  type="submit"
                  // disabled={true}
                  // disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />  // Show spinner when loading
                  ) : (
                    <Box className="base-text i_understand_text" sx={{
                      display: 'flex',
                      fontWeight: '500', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      marginTop: '2px',
                      color: '#F2F2F2',
                      fontSize: '20px',
                    }}>
                      Create Account
                    </Box>
                  )}
                </Button>

                <Box className="base-text" sx={{marginTop: '30px', color: themeColors.color}}>
                  Already have an account? <Link 
                    sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                    onClick={() => {
                      setUserData({...userData, uuidHasAccount: true})
                      navigate('/register')
                    }}
                  >Sign In
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          </Box>
      </form>
    }
    </Box>
  </Box>
  )
}

export default SignUp

const textFieldStyles = {
  marginBottom: '15px',
  '& .MuiInputBase-input': { // This targets the input itself
    color: themeColors.color,
  },
  '& .MuiFormLabel-root': { // This targets the label
    color: themeColors.color,
  },
  '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
    color: themeColors.color,
    '& fieldset': {
      borderColor: themeColors.color,
    },
    '&:hover fieldset': {
      borderColor: themeColors.color,
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.color,
    },
  },
}
